import { FC } from 'react';

import {
  Wrapper,
  Header,
  Area,

  /* Hidden because no category name in the API response, */
  // Category,

  Body,
  Content
} from './styled';

import { IMissionStatement } from 'Redux/ListMissionStatements/types';

interface IProps {
  data: IMissionStatement;
}

const MissionItem: FC<IProps> = ({ data }) => {
  const { area, conclusion } = data;

  return (
    <Wrapper>
      <Header>
        <Area>{area}</Area>

        {/* Hidden because no category name in the API response, */}
        {/* <Category>Net Asset Valuation Process</Category> */}
      </Header>
      <Body>
        <Content>{conclusion}</Content>
      </Body>
    </Wrapper>
  );
}

export default MissionItem;
