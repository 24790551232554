import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Wrapper,
  Row,
  Col,
  Label,
  Title,
  IconWrapper,
  Icon
} from './styled';

// @ts-ignore
import completedIcon from "assets/images/achievements/completed.svg";

// @ts-ignore
import pendingIcon from "assets/images/achievements/pending.svg";

// @ts-ignore
import overdueIcon from "assets/images/achievements/overdue.svg";

import type { IAreaProgress, IState } from '@types';

import { useQueryParams } from 'utils/useQueryParams';
import useWindowSize from 'utils/useWindowSize';

// @ts-ignore
import { BREAKPOINTS } from "theme/mixins";

import { GetAreaStatuses } from 'Redux/Achievements/ListAreaStatuses/action';

enum Status {
  Overdue = "Overdue",
  Completed = "Completed",
  Pending = "In Progress"
}

interface IDetailsTable {
  masterPlanId: string | null;
}

const getIcon = (status: string) => {
  switch (status) {
    case Status.Completed:
      return completedIcon;
    case Status.Pending:
      return pendingIcon;
    case Status.Overdue:
      return overdueIcon;
    default:
      return pendingIcon;
  }
}

const DetailsTable: FC<IDetailsTable> = ({ masterPlanId }) => {
  const dispatch = useDispatch();

  const { data: areasProgressData } = useSelector((state: IState) => state.listAreasProgress);
  const { data } = useSelector((state: IState) => state.listAreaStatuses);

  const [userArea, setUserArea] = useState<IAreaProgress | null>(null);

  const { trend: trendIndex } = useQueryParams();
  const { width } = useWindowSize();

  useEffect(() => {
    const userAreaData = areasProgressData[parseInt(trendIndex || '1') - 1] as IAreaProgress || null;

    if (userAreaData) {
      setUserArea(userAreaData);
    }
  }, [trendIndex, areasProgressData]);

  useEffect(() => {
    if (masterPlanId && userArea && userArea.user_area_name) {
      dispatch(GetAreaStatuses({
        domain: masterPlanId,
        area: userArea.user_area_name
      }));
    }
  }, [masterPlanId, userArea]);

  const isMobileView = !!(width && width < BREAKPOINTS.sm);

  return (
    <Wrapper>
      <Row $primary>
        <Col $primary>
          <Title>
            {/* <Icon src="https://goal-setting-workbo-37498.s3.amazonaws.com/media/area/icons/Sleep_3C2D87_gFaFMqQ.png" alt="" /> */}
            {isMobileView ? null : userArea?.user_area_name} Area Actions
          </Title>
        </Col>
        <Col $secondary>
          <Title>
            Status
          </Title>
        </Col>
      </Row>

      {data.map(item => <Row key={item.goal_id}>
        <Col $primary>
          <Label>{item.category_name}</Label>
        </Col>
        <Col $secondary>
          <IconWrapper>
            <Icon src={getIcon(item.status)} alt="" />
          </IconWrapper>
        </Col>
      </Row>)}
    </Wrapper>
  );
}

export default DetailsTable;
