import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  color: #1271A6;
  leading-trim: both;
  text-edge: cap;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  padding: 10px;
  height: 47px;
  border-bottom: 1px solid #C1CAD1;
  background: #fff;

  ${({ theme }) => theme.min("sm")`
    display: none;
  `}
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  ${({ theme }) => theme.max("lg")`
    padding: 16px 35px;
  `}

  ${({ theme }) => theme.max("sm")`
    padding: 16px 20px;
  `}
`;
