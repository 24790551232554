import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import styled, { css } from "styled-components";

import { ReactComponent as ExpandArrow } from "assets/images/expand_arrow.svg";

// import { ReactComponent as Home } from "assets/images/sidebar/home.svg";
import { ReactComponent as Dashboard } from "assets/images/sidebar/dashboard.svg";
import { ReactComponent as Flag } from "assets/images/sidebar/flag.svg";
import { ReactComponent as Achievements } from "assets/images/sidebar/achievements.svg";

/* import { ReactComponent as Target } from "assets/images/sidebar/goal.svg"; */
// import { ReactComponent as Sort } from "assets/images/sidebar/priority.svg";
/* import { ReactComponent as Calendar } from "assets/images/sidebar/calendar.svg";*/
import { ReactComponent as Tick } from "assets/images/sidebar/tick.svg";

import { ReactComponent as User } from "assets/images/sidebar/profile.svg";

import { ReactComponent as Coaching } from "assets/images/sidebar/coaching.svg";

import { max } from "theme/mixins";

/* import { ReactComponent as Tick } from "assets/images/ticked.svg";
import { ReactComponent as Chat } from "assets/images/chat.svg";
import { ReactComponent as Compass } from "assets/images/compass.svg";
import { ReactComponent as User } from "assets/images/user.svg";
import { ReactComponent as Achievement } from "assets/images/achievement.svg";
import { ReactComponent as Manual } from "assets/images/manual.svg";
import { ReactComponent as Settings } from "assets/images/settings.svg";
import { ReactComponent as Message } from "assets/images/msgs.svg"; */

import {
  APM_PATH,
  COMPLETED_GOALS_PATH,
  ACHIEVEMENTS_PATH,
  PROFILE_PATH,
  JOURNEY_PATH,
  COACHING_PATH
} from "utils/routes";

const SchedulerSideNav = ({ position }) => {
  const location = useLocation().pathname;
  const [isExpand, setIsExpand] = useState(false);

  const $element = useRef(null);

  const { success: userData } = useSelector((state) => state.retrieveUserDataReducer);

  const handleClickOutside = event => {
    if ($element.current && !$element.current.contains(event.target)) {
      setIsExpand(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let sideNavRoutes = [];

  if (location.includes(APM_PATH)) {
    sideNavRoutes = [
      {
        logo: <Dashboard />,
        title: "Priority Dashboard",
        route: APM_PATH,
        disabled: false,
        splitRange: 1,
      },
      {
        logo: <Flag />,
        title: "Journeys",
        route: JOURNEY_PATH,
        splitRange: 1,
        disabled: false,
      },
      {
        logo: <User />,
        title: "User Profile",
        route: `${APM_PATH}${PROFILE_PATH}`,
        disabled: false,
        splitRange: 1,
      }
    ];

    if (userData?.type === 'Coach') {
      sideNavRoutes.push({
        logo: <Coaching />,
        title: "Coaching",
        route: `${APM_PATH}${COACHING_PATH}`,
        disabled: false,
        splitRange: 1,
        includes: true
      });
    }
  }
  else {
    sideNavRoutes = [
      {
        logo: <Flag />,
        title: "Journeys",
        route: JOURNEY_PATH,
        splitRange: 1,
        disabled: false,
      },
      {
        logo: <Tick />,
        title: "Completed Goals",
        route: COMPLETED_GOALS_PATH,
        disabled: false,
        splitRange: 1,
      },
      {
        logo: <Achievements />,
        title: "Achievements",
        route: ACHIEVEMENTS_PATH,
        disabled: false,
        splitRange: 1,
      },
    ]
  }

  // const sideNavRoutes = [
    /* {
      logo: <Home />,
      title: "Home",
      route: HOME_PATH,
      splitRange: 1,
      disabled: false,
    }, */
    /* {
      logo: <Target />,
      title: "Goals",
      route: HIERARCHY_PATH,
      disabled: false,
      splitRange: 1,
    },*/
    /*{
      logo: <Calendar />,
      title: "Schedule",
      route: WSH_PATH,
      disabled: false,
      splitRange: 1,
    }, */
    /* {
      logo: <Message />,
      title: "Journal",
      route: "journal",
      disabled: true,
      splitRange: 2,
    }, */
    /* {
      logo: <Compass />,
      title: "Clarity",
      route: "clarity",
      disabled: true,
      splitRange: 2,
    },
    {
      logo: <User />,
      title: "Community",
      route: "community",
      disabled: true,
      splitRange: 2,
    },
    {
      logo: <Achievement />,
      title: "Challenges",
      route: "challenges",
      disabled: true,
      splitRange: 2,
    },
    {
      logo: <Manual />,
      title: "Learn",
      route: "learn",
      disabled: true,
      splitRange: 2,
    },
    {
      logo: <Chat />,
      title: "My Coach",
      route: "my-coach",
      disabled: true,
      splitRange: 2,
    },
    {
      logo: <Settings />,
      title: "Settings",
      route: "settings",
      disabled: true,
      splitRange: 2,
    } */
  // ];

  const handleExpand = (isExpand) => {
    setIsExpand(!isExpand);
  };

  return (
    <Wrapper $isExpand={isExpand} ref={$element} $position={position} $bcolor={"white"}>
      <ExpandIconWrapper type="button" isExpand={isExpand} onClick={() => handleExpand(isExpand)} title={isExpand ? 'Collapse' : 'Expand'}>
        <ExpandArrow />
      </ExpandIconWrapper>
      
      <LinkContainer>
        {sideNavRoutes?.map((item) => {
          const status = item?.includes ? location.includes(item.route) : location === item.route;

          return (
            <NavigationLink
              to={item.route}
              $expand={isExpand}
              $status={status}
              $disabled={item.disabled}
              $hidden={item.hidden && !status}
              title={item.title}
              key={item.title}
            >
              <IconWrapper isExpand={isExpand} isDisabled={item.disabled}>
                <Icon status={status}>
                  {item.logo}
                </Icon>
              </IconWrapper>
              <LinkText
                isExpand={isExpand}
                status={status}
              >
                {item.title}
              </LinkText>
            </NavigationLink>
          )
        })}
      </LinkContainer>
    </Wrapper>
  );
};

export default SchedulerSideNav;

const Wrapper = styled.div`
  width: 66px;
  transition: width 0.15s ease-in-out;
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  position: absolute;
  background-color: #ffffff;
  border-right: 1px solid #C1CAD1;
  color: ${({ color }) => color ? color : ""};

  ${max('sm')`
    height: 100vh;
    z-index: 999999;
  `}
 
  ${({ $isExpand }) => $isExpand && `
    width: 260px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  `};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #C1CAD1;
  }
`;

const LinkContainer = styled.div`
  background-color: ${({ theme }) => theme?.background?.light};
`;

const NavigationLink = styled(Link)`
  border-left: 4px solid transparent;
  background-color: ${({ $status, theme }) => $status ? theme?.active : theme?.background?.light};
  text-decoration: none;
  display: flex;
  line-height: 20px;
  letter-spacing: 0;
  padding-right: 4px;
  align-items: center;
  justify-content: ${({ $expand }) => ($expand ? "flex-start" : "center")};
  height: 55px;
  pointer-events: ${({ $disabled }) => $disabled && "none"};
  cursor: ${({ $disabled }) => $disabled && "not-allowed"};
  opacity: ${({ $disabled }) => $disabled && "0.5"};

  ${({ $status, theme }) => $status && theme && css`
    border-left-color: ${theme?.primary?.light};
  `}

  ${({ $hidden }) => $hidden && css`
    display: none;
  `}

  ${({ $status }) => $status || css`
    &:hover {
      background-color: #E8EBEE;
    }
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};
  width: ${({ isExpand }) => (isExpand ? "15%" : "100%")};
`;

const Icon = styled.div`
  svg {
    path {
      fill: ${({ status, theme }) => status && theme?.primary?.light};
    }
  }
`;

const ExpandIconWrapper = styled.button`
  display: flex;
  justify-content: ${({ isExpand }) => (isExpand ? "flex-start" : "center")};
  align-items: center;
  height: auto;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  transform: rotate(${({ isExpand }) => isExpand && "180deg"});
  padding-left: ${({ isExpand }) => isExpand && "10px"};
  background-color: transparent;
  border: none;

  svg {
    path {
      fill: ${({ status, theme }) => status && theme?.primary?.light};
    }
  }
`;

const LinkText = styled.p`
  width: 85%;
  margin: 0;
  padding: 0;
  color: ${({ status, theme }) => status ? theme?.primary?.light : theme?.text?.secondaryLight};
  white-space: nowrap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  display: ${({ isExpand }) => (isExpand ? "block" : "none")};
  padding-top: 5px;
`;
