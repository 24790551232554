import styled, { css } from 'styled-components';

interface IWrapper {
  $active: number;
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;

  .apexcharts-xaxis-tick {
    display: none;
  }

  .apexcharts-bar-area {
    opacity: 0.5;
  }

  ${({ $active }) => $active != null && `
    .apexcharts-bar-area:nth-child(${$active+1}) {
      opacity: 1;
    }
  `}
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 0 54px;
  gap: 12px;
`;

export const Category = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: calc(100% / 7);
  
  label {
    align-items: center;
    word-break: break-word;
    text-align: center;

    svg {
      min-width: 19px;
    }
  }
`;

export const CheckboxLabel = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  line-height: 18px;
`;
