import styled, { css } from 'styled-components';

const colors = {
  primary: {
    goal: '#17C1D9',
    plan: '#FF5733',
  },
  secondary: {
    goal: '#17C1D9',
    plan: '#7400CF',
  }
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 53px;

  ${({ theme }) => theme.max("lg")`
    gap: 24px;
  `}

  ${({ theme }) => theme.max("sm")`
    gap: 8px;
  `}
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) => theme.max("sm")`
    gap: 4px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) => theme.max("sm")`
    flex-direction: row;
    align-items: flex-end;
  `}
`;

export const Title = styled.span`
  color: #1F2026;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;

  ${({ theme }) => theme.max("sm")`
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Icon = styled.img`
  width: 32px;
  heigh: 32px;

  ${({ theme }) => theme.max("sm")`
    width: 18px;
    height: 18px;
  `}
`;

export const Percent = styled.span`
  color: #1F2026;
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.32px;

  ${({ theme }) => theme.max("sm")`
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.14px;
  `}
`;

export const Text = styled.span`
  color: #767A85;
  font-family: Inter;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;

  ${({ theme }) => theme.max("sm")`
    font-size: 10px;
    letter-spacing: -0.2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

interface BarProps {
  $type: 'goal' | 'plan';
  $alt: boolean;
  $value: string | number;
}

export const Bar = styled.div<BarProps>`
  height: 2px;
  background: #D9D9D9;

  ::before {
    content: '';
    height: 100%;

    ${({ $type, $alt }) => $type && css`
      background: ${colors[$alt ? 'secondary' : 'primary'][$type]};
    `}

    width: ${({ $value }) => `${$value}%`};
    display: block;
  }
`;
