// @ts-ignore
import { BREAKPOINTS } from "theme/mixins.js";

export const options = {
  chart: {
    type: 'bar' as 'bar',
    width: '100%',
    height: 240,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    }
  },
  responsive: [{
    breakpoint: BREAKPOINTS.lg,
    options: {
      chart: {
        height: 220
      }
    },
  }],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: 16,
      borderRadius: 1.3,
      borderRadiusApplication: 'around' as 'around',
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      }
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 6,
    colors: ['transparent']
  },
  xaxis: {
    labels: {
      show: false,
    }
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 2,
    labels: {
      align: 'left' as 'left',
      style: {
        colors: ['#6B7280'],
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 500
      },
      offsetY: 3,
      formatter: (value: any) => value.toFixed(0),
    },
  },
  grid: {
    borderColor: 'rgba(229, 231, 235, 0.50)',
    row: {
      colors: ['#fff']
    }
  },
  legend: {
    show: false
  }
}
