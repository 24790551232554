import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { IOption } from '../CustomSelect';

import CustomSelect from '../CustomSelect';

import {
  Wrapper,
  Group,
  Title,
  Section,
  Label,
  Footer,
  Button
} from "./styled";

import { useQueryParams } from 'utils/useQueryParams';

import { GetAreasProgress } from 'Redux/Achievements/ListAreasProgress/action';

interface IProps {
  onClose: (data: any, area: any) => void;
}

interface IState {
  domainListReducer: {
    domainList: any;
  }
}

const allJourneyOption = {
  value: null,
  label: "All Journeys",
  optionLabel: "Select All Journeys"
}

const allAreaOption = {
  value: null,
  label: "All Areas",
  optionLabel: "Select All Areas"
}

const FilterContent: FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();

  const { journey: journeyId, area: areaName, setQueryParams } = useQueryParams();

  const { domainList } = useSelector((state: IState) => state.domainListReducer);

  const [journey, setJourney] = useState<IOption>(allJourneyOption);
  const [area, setArea] = useState<IOption>(allAreaOption);

  const [areas, setAreas] = useState<IOption[]>([]);

  useEffect(() => {
    if (journeyId) {
      const domain = domainList.find((d: any) => d.id === journeyId);

      if (domain) {
        setJourney({ value: domain.id, label: domain.name });

        if (areaName) {
          setArea({ value: areaName, label: areaName });
        }
        else {
          setArea(allAreaOption);
        }
      }
    }
    
  }, [journeyId, areaName]);

  useEffect(() => {
    if (journey) {
      const domain = domainList.find((d: any) => d.id === journey.value);

      if (domain && domain?.current_state?.id) {
        dispatch(GetAreasProgress({
          domain: domain.current_state.id
        }, true, (data: any) => {
          setAreas(data.map((item: any) => ({
            value: item.user_area_name,
            label: item.user_area_name
          })));
        }));
      }
      else {
        setAreas([]);
      }
    }
  }, [journey]);

  const journeysData = [
    allJourneyOption,
    ...domainList
      .filter((item: any) => item?.current_state.status !== "Not started")
      .map((item: any) => ({
        value: item.id,
        label: item.name
      }))
  ];

  const areasData = [
    allAreaOption,
    ...areas
  ]

  const handleJourneySelect = (item: IOption) => {
    setJourney(item);
    setArea(allAreaOption);
  }

  const handleAreaSelect = (item: IOption) => {
    setArea(item);
  }

  const handleClick = () => {
    const areaIndex = area.value ? areasData.findIndex((item: any) => item.value === area.value) : 1;

    const filters = {
      journey: (journey.value ? journey.value : '').toString(),
      area: (area.value ? area.label : '').toString(),
      trend: areaIndex.toString()
    };

    setQueryParams(filters);
    onClose(journey, area);
  }

  return (
    <Wrapper>
      <Group>
        <Title>Journey</Title>
        <Section>
          <Label>Select Journey</Label>
          <CustomSelect
            title="Journeys"
            value={journey}
            options={journeysData}
            onChange={handleJourneySelect}
          />
        </Section>
      </Group>

      <Group $disabled={!journey.value}>
        <Title>Area</Title>
        <Section>
          <Label>Select Area</Label>
          <CustomSelect
            title="Areas"
            value={area}
            options={areasData}
            disabled={!journey.value}
            onChange={handleAreaSelect}
          />
        </Section>
      </Group>

      <Footer>
        <Button onClick={handleClick}>Done</Button>
      </Footer>
    </Wrapper>
  );
}

export default FilterContent;
