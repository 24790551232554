import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px 35px 48px;
  background: #fff;

  ${({ theme }) => theme.max("lg")`
    padding: 0 0 24px 0;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
