import React, { FC, useRef } from 'react';
import { Popover } from 'antd';

// @ts-ignore
import closeIcon from "assets/images/achievements/close.svg";

// @ts-ignore
import arrowDownIcon from "assets/images/achievements/arrow-down.svg";

import {
  Toggle,
  Icon,

  Content,
  Header,
  Title,
  Close,
  Body,
  Option
} from "./styled";

export interface IOption {
  value: string | number | null;
  label: string | number;
  optionLabel?: string | number;
}

interface IProps {
  title: string;
  value: IOption;
  options: IOption[];
  disabled?: boolean;
  onChange?: (option: IOption) => void;
}

const FilterWidget: FC<IProps> = ({ title, value, options, disabled, onChange }) => {
  const [open, setOpen] = React.useState(false);

  const $timePopover = useRef();

  const handleClose = () => {
    setOpen(false);

    if ($timePopover.current) {
      // @ts-ignore
      $timePopover.current.clear();
    }
  };

  const handleClick = (option: IOption) => () => {
    if (onChange) {
      onChange(option);
    }

    handleClose();
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const PopoverContent = () => {
    return (
      <Content>
        <Header>
          <Title>{title}</Title>
          <Close onClick={handleClose}>
            <Icon $lg src={closeIcon} alt="" />
          </Close>
        </Header>

        <Body>
          {options.map((option, index) => (
            <Option key={index} onClick={handleClick(option)}>{option.optionLabel || option.label}</Option>
          ))}
        </Body>
      </Content>
    )
  }

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: [0, -7] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      overlayClassName="any_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <Toggle disabled={disabled}>
        <span>{value.label}</span>
        <Icon src={arrowDownIcon} alt="" />
      </Toggle>
    </Popover>
  );
}

export default FilterWidget;
