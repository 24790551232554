{/* TODO. Hidden because no backend logic yet */}
/* import { useState } from 'react';
import moment from 'moment'; */

{/* TODO. Hidden because no backend logic yet */}
// import CalendarWidget from './CalendarWidget';

import FilterWidget from './FilterWidget';

import {
  Wrapper,
  Top,
  Content
} from './styled';

const Header = () => {
  {/* TODO. Hidden because no backend logic yet */}
  /* const [dateRange, setDateRange] = useState<any>([moment().startOf('week'), moment().endOf('week')]);

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  } */

  return (
    <Wrapper>
      <Top>Achievements</Top>

      <Content>
        <FilterWidget />

        {/* TODO. Hidden because no backend logic yet */}
        {/* <CalendarWidget
          value={dateRange}
          onChange={handleDateRangeChange}
        /> */}
      </Content>
    </Wrapper>
  );
}

export default Header;
