import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

type QueryParams = Record<string, string | undefined>;

export function useQueryParams<T extends QueryParams = QueryParams>() {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Convert the URLSearchParams into a plain object.
   * This object will be re-computed whenever 'searchParams' changes,
   * causing any component using this hook to re-render with fresh data.
   */
  const queryParamsObject = React.useMemo(() => {
    const paramsObj: Partial<T> = {};
    searchParams.forEach((value, key) => {
      paramsObj[key as keyof T] = value as T[keyof T];
    });
    return paramsObj as T;
  }, [searchParams]);

  /**
   * Update the URL search parameters.
   *
   * - If a param value is `undefined` or `null`, that param is deleted from the URL.
   * - Otherwise, it is set/updated.
   * 
   * This triggers a re-render in any components using this hook because
   * the `searchParams` object changes.
   */
  const setQueryParams = React.useCallback(
    (newParams: Partial<T>) => {
      const updatedSearchParams = new URLSearchParams(searchParams);

      Object.entries(newParams).forEach(([key, value]) => {
        if (value == null) {
          updatedSearchParams.delete(key);
        } else {
          updatedSearchParams.set(key, value as string);
        }
      });

      setSearchParams(updatedSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return {
    // Spread the object so you can destructure like: const { area, journey, setQueryParams } = useQueryParams();
    ...queryParamsObject,
    // Or access .journey, .area, etc. directly if you prefer
    queryParams: queryParamsObject,
    setQueryParams,
  };
}
