// /* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unreachable */
import React, {
  createRef,
  // forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// import styled, { css } from "styled-components";
// import { Avatar, Progress, Select } from "antd";
// import { PlusOutlined, MoreOutlined } from "@ant-design/icons";

import { useLocation, useNavigate } from "react-router-dom";
// import Drag from "assets/images/dragicon.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setCookie, getCookie } from "utils/StorageVariables";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { SetSelectedGoalAction } from "Redux/Global/action";

// import OngoingIcon from "assets/images/journeys/ongoing.svg";
import plusIcon from "assets/images/journeys/plus.svg";

// import { ReactComponent as ThreeDotsIcon } from "assets/images/journeys/three-dots.svg";

import { DeleteActions } from "Redux/DeleteActions/action";
import { Error } from "Responses/Response";
import { UpdateActions } from "Redux/UpdateActions/action";
import { UpdateGoal } from "Redux/UpdateGoal/action";
import { CreateAction } from "Redux/CreateAction/action";
import { UpdateLastStep } from "Redux/UpdateLastStep/action";
import { PrioritizeActionPlan } from "Redux/PrioritizeActionPlans/action";
import { ListAccountabilities, CreateAccountability } from "Redux/ListAccountability/action";

import _ from "lodash";

import useDeviceType from "../../../Hooks/ResponsiveHook";
import "react-datepicker/dist/react-datepicker.css";
// import useWindowSize from "utils/useWindowSize";
// import { BREAKPOINTS } from "theme/mixins";
// import Switch from "react-switch";
// import LockedOver from "components/LockedOver/LockedOver";
import { UpdateUserArea } from "Redux/UpdateUserArea/action";
import CompleteActionItemModal from "components/CompleteActionItemModal";
import { AssistantProvider } from "components/InputAreaAI";
import LockedOver from "components/LockedOver/LockedOver";
import PlanShortcut from "components/PlanShortcut";
import AccountabilitySelect, { getGroupedOptions, getOptionWithLabel } from "./AccountabilitySelect";

import Header from "./Header";
import Print from './Print';

// import { GetProgress } from "Redux/GetProgress/action";

import { MASTER_PATH, PURPOSE_PATH } from "utils/routes";

import * as Styles from "./styled.js";
import StepLayout from "../StepLayout/index";
import ActionItem, { DraggableActionItem } from "./ActionItem/index";
import Pagination from "./Pagination/index";

import TypeTooltip from "./TypeTooltip/index";

import { Toggle, Dropdown, Tooltip, Checkbox } from "elements";

import RadialBar from "components/PriorityApp/SimpleView/RadialBar";
import { GetPredefinedMetrics } from "Redux/GetPredefinedMetrics/action";
import { GetCustomMetrics } from "Redux/CustomMetrics/action";
import {
  CreateMetricsPopup,
  ManageMetricsPopup,
} from "../Measurement/ManageMetrics";

// import ChevronRightIcon from "assets/images/journeys/chevron-right.svg";
import CustomDatepicker from "./CustomDatepicker";

// Custom hook to create a debounced function
const useDebouncedCallback = (callback, delay) => {
  const debouncedCallback = useCallback(
    _.debounce((...args) => callback(...args), delay),
    [callback, delay]
  );
  return debouncedCallback;
};

const Actions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const $delay = useRef(false);

  const queryParams = new URLSearchParams(location.search);
  const journey = queryParams.get('m');
  const sanitizedJourney = journey?.endsWith('/') ? journey?.slice(0, -1) : journey;

  
  const [actionPlanData, setActionPlanData] = useState([]);
  const [showCompletedActions, setShowCompletedActions] = useState(false);
  const [open, setOpen] = useState(null);
  // const [strategies, setStrategies] = useState([]);
  const [isAddCustomMetric, setIsAddCustomMetric] = useState(false);
  const [isManageMetric, setIsManageMetric] = useState(false);
  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";
  const isLargTablet = window.innerWidth > 1024 && window.innerWidth < 1256

  const { masterPlanDetails, loading: masterplanLoading } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );
  const { selectedGoalAction } = useSelector((state) => state.globalReducer);
  const { data: accountabilityData, custom: customAccountabilityData } = useSelector((state) => state.accountabilityReducer);
  const { loading } = useSelector((state) => state.createActionReducer);

  const { actions_progress: actionsProgress } = useSelector(
    (state) => state.getProgressReducer
  );

  const { SelectedClient } = useSelector((state) => state.globalReducer);
  useEffect(() => {
    let MasterPlanId = sanitizedJourney || getCookie("MasterPlanId");
    if (sanitizedJourney ) {
      setCookie("MasterPlanId", sanitizedJourney);
    }
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
    dispatch(GetPredefinedMetrics());
    dispatch(GetCustomMetrics());
  }, [journey]);

  useEffect(() => {
    if (!masterPlanDetails?.journey?.id)
      return;

    dispatch(ListAccountabilities(masterPlanDetails?.journey?.id));
    dispatch(ListAccountabilities(masterPlanDetails?.journey?.id, true));
  }, [masterPlanDetails?.journey?.id]);

  useEffect(() => {
    if (!masterPlanDetails?.areas) return;
    setActionPlanData(SortArr([...masterPlanDetails?.areas]));
  }, [masterPlanDetails?.areas]);

  useEffect(() => {
    if (!masterPlanDetails) return;

    if (!actionPlanData.length) return;

    const index = 0;

    if (
      !selectedGoalAction?.parent_masterplan ||
      selectedGoalAction?.parent_masterplan !== masterPlanDetails.id
    ) {
      dispatch(
        SetSelectedGoalAction({
          ...actionPlanData[index],
          i: index,
        })
      );
    }
  }, [actionPlanData, masterPlanDetails]);

  const SortArr = (data) =>
    data?.sort(function (a, b) {
      return a?.goal?.priority - b?.goal?.priority;
    });

  const handleUpdate = (
    actionId,
    foundAction,
    isProgress = false,
    showLoader = false
  ) => {
    return dispatch(
      UpdateActions(actionId, { ...foundAction }, isProgress, { showLoader })
    );
  };

  const debouncedHandleUpdate = useDebouncedCallback(handleUpdate, 500);

  /* const handleUpdate = useCallback(
    _.debounce(
      (actionId, foundAction, isProgress = false) =>
        dispatch(UpdateActions(actionId, { ...foundAction }, isProgress, { showLoader: false })),
      500
    ),
    []
  ); */

  const onAddMetricPopupOpenHandle = () => {
    setIsAddCustomMetric(true);
  };

  const onAddMetricPopupCloseHandle = () => {
    setIsAddCustomMetric(false);
  };

  const onManageToggleHandle = (action) => {
    if (action) onAddMetricPopupOpenHandle();
    setIsManageMetric((prev) => !prev);
  };

  const handleChange = async (
    e,
    id,
    actionId,
    i,
    actionIndex,
    isProgress,
    isRedux = true
  ) => {
    let dataCopy = [...actionPlanData];
    let findedObj = dataCopy?.find((item) => item?.id === id);
    let actionsCopy = [...findedObj.goal.actions];
    let foundActionIndex = actionsCopy.findIndex(
      (item) => item?.id === actionId
    );

    let foundAction =
      e.target.name === "deadline" || e.target.name === "startline"
        ? {
            ...actionsCopy[foundActionIndex],
            [e.target.name]: e.target.value,
          }
        : {
            ...actionsCopy[foundActionIndex],
            [e.target.name || e.target?.attributes?.name?.value]:
              e.target.value !== undefined && e.target.value !== null
                ? e.target.value
                : e.target.innerText !== undefined &&
                  e.target.innerText !== null
                ? e.target.innerText
                : e.target.name === "completed" && e.target.value,
          };

    actionsCopy[foundActionIndex] = foundAction;
    findedObj.goal.actions = actionsCopy;

    if (e.target.name === "completed") {
      findedObj.goal.actions = [...getPrioritizedActionItems({ ...findedObj })];

      await dispatch(
        UpdateActions(actionId, { ...foundAction }, isProgress, {
          showLoader: false,
        })
      );
      prioritizeActionItems({ ...findedObj }, findedObj?.id, {
        shouldUpdate: false,
        showLoader: false,
      });
    } else {
      if (isRedux) {
        debouncedHandleUpdate(actionId, foundAction, isProgress);

        setTimeout(() => {
          $delay.current = false;
        }, 500);
      } else {
        $delay.current = true;
      }
    }

    dispatch(SetSelectedGoalAction({ ...findedObj, i }));

    setActionPlanData(dataCopy);
  };

  const handleAccountabilityCreate = (value, callback) => {
    const existingAccountability = [...accountabilityData, ...customAccountabilityData].find(
      (item) => item.name === value
    );

    if (existingAccountability) {
      callback(null);
      return;
    }

    const formData = new FormData();

    formData.append('name', value);
    formData.append('domain', masterPlanDetails?.journey?.id);
    formData.append('user', getCookie('userData') ? JSON.parse(getCookie('userData')).id : null);
    formData.append('is_custom', true);

    dispatch(CreateAccountability(formData, callback));
  };

  const handleGoalChange = (e, id, i) => {
    let dataCopy = [...actionPlanData];
    let findedObj = dataCopy?.find((item) => item?.id === id);

    if (e.target.name === "startline" && findedObj?.goal?.deadline) {
      let isError = validateDate(findedObj?.goal?.deadline, e.target.value);
      if (isError) return Error(isError);
    }

    findedObj.goal = { ...findedObj?.goal, [e.target.name]: e.target.value };
    dataCopy[i] = findedObj;

    dispatch(SetSelectedGoalAction({ ...findedObj, i }));
    dispatch(
      UpdateGoal(findedObj.goal?.id, { [e.target.name]: e.target.value })
    );
    setActionPlanData(dataCopy);
  };

  const handleGoalUpdate = (form, id, callback) => {
    let dataCopy = [...actionPlanData];
    let findedIndex = dataCopy.findIndex((item) => item.id === id);

    const onCallback = () => {
      if (callback) {
        callback();
      }
    }
  
    if (findedIndex !== -1) {
      dataCopy[findedIndex].goal = { ...dataCopy[findedIndex].goal, ...form };
      dispatch(UpdateGoal(dataCopy[findedIndex].goal.id, form, onCallback));
      setActionPlanData(dataCopy);
    }
  };

  const handleCompleteGoal = (e, id, i) => {
    setOpen({ e, id });
  };

  const handleCompleteAction = ({ e, id }) => {
    let dataCopy = [...actionPlanData];
    let findedObj = dataCopy?.find((item) => item?.id === id);

    dispatch(
      UpdateGoal(
        findedObj.goal?.id,
        { [e.target.name]: e.target.value },
        () => {
          findedObj.goal = {
            ...findedObj?.goal,
            actions: [
              ...findedObj?.goal.actions.map((action) => ({
                ...action,
                completed: true,
              })),
            ],
          };
          prioritizeActionItems({ ...findedObj }, findedObj?.id, {
            showLoader: false,
          });
        }
      )
    );

    setOpen(null);
  };

  const handleAddMore = (id) => {
    setTimeout(
      () => {
        let dataCopy = [...actionPlanData];
        let actionObj = {
          habit: false,
        };
        let findedObj = dataCopy?.find((item) => item?.id === id);
        let findedIndex = dataCopy?.findIndex((item) => item?.id === id);

        dispatch(
          CreateAction(
            { ...actionObj, goal: findedObj?.goal?.id },
            (data) => {
              findedObj.goal = {
                ...findedObj?.goal,
                actions: [...findedObj?.goal.actions, data],
              };

              if (findedIndex !== -1) {
                dataCopy[findedIndex] = findedObj;
              }

              prioritizeActionItems({ ...findedObj }, findedObj?.id, { propagate: true });
            },
            { update: false }
          )
        );
      },
      $delay.current ? 500 : 0
    );
  };

  const handleOrderAgain = (arr, start, end) => {
    let copyArr = [...arr];
    let element = copyArr.splice(start, 1)[0];
    copyArr.splice(end, 0, element);
    return copyArr;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let copyActionPlans = [...actionPlanData];
    let foundObj = copyActionPlans?.find(
      (item) => item?.id === result?.destination?.droppableId
    );

    // First, sort the array so that completed: false items are at the top
    // let sortedActions = foundObj?.goal?.actions.filter(item => !item.completed).sort((a, b) => a.completed === b.completed ? 0 : a.completed ? 1 : -1);
    let sortedActions = selectedGoalAction?.goal?.actions
      // .filter((item) => !item.completed)
      .sort((a, b) => (a.completed === b.completed ? 0 : a.completed ? 1 : -1));

    let orderedArr = handleOrderAgain(
      sortedActions,
      result?.source?.index,
      result?.destination?.index
    );

    let parentIndex = actionPlanData.findIndex(
      (item) => item?.id === result?.destination?.droppableId
    );

    foundObj.goal.actions =
      orderedArr /* .sort((a, b) => a.completed === b.completed ? 0 : a.completed ? 1 : -1) */
        .map((item, i) => ({ ...item, priority: i }));
    copyActionPlans[parentIndex] = foundObj;

    setActionPlanData([...copyActionPlans]);
    dispatch(SetSelectedGoalAction({ ...foundObj, i: selectedGoalAction?.i }));

    let masterplanId = getCookie("MasterPlanId");

    dispatch(
      PrioritizeActionPlan(
        masterplanId,
        orderedArr?.map((action, i) => {
          return {
            action: action?.id,
            priority: i,
          };
        }),
        { showLoader: false }
      )
    );
  };

  const getPrioritizedActionItems = (goalAction) => {
    let sortedActions = goalAction?.goal?.actions.sort((a, b) =>
      a.completed === b.completed ? 0 : a.completed ? 1 : -1
    );

    return sortedActions?.map((action, i) => {
      return {
        ...action,
        priority: i,
      };
    });
  };

  const prioritizeActionItems = (
    goalAction,
    goalId,
    { shouldUpdate = true, showLoader = true, propagate = false } = {}
  ) => {
    let sortedActions = [...goalAction?.goal?.actions].sort((a, b) =>
      a.completed === b.completed ? 0 : a.completed ? 1 : -1
    );
    let masterplanId = getCookie("MasterPlanId");

    const items = sortedActions?.map((action, i) => {
      return {
        action: action?.id,
        priority: i,
      };
    });

    dispatch(
      PrioritizeActionPlan(masterplanId, items, {
        propagate,
        callback: (data) => {
          if (!shouldUpdate) return;

          let findedObj = [...data.areas].find((item) => item?.id === goalId);

          dispatch(
            SetSelectedGoalAction({
              ...selectedGoalAction,
              goal: { ...selectedGoalAction?.goal, ...findedObj?.goal },
            })
          );
          $delay.current = false;
        },
        showLoader,
      })
    );
  };

  const onMenuClick = (goalId, actionId, i, key, actionIndex, isProgress) => {
    if (key === "1") {
      let actionPlanDataCopy = [...actionPlanData];
      let foundObj = actionPlanDataCopy?.find((item) => item?.id === goalId);
      let foundAction = foundObj?.goal?.actions?.find(
        (action) => action?.id === actionId
      );
      handleChange(
        { target: { value: !foundAction?.completed, name: "completed" } },
        goalId,
        actionId,
        i,
        actionIndex,
        isProgress
      );
    }
    if (key === "2") {
      let actionPlanDataCopy = [...actionPlanData];
      let foundObj = {
        ...actionPlanDataCopy?.find((item) => item?.id === goalId),
      };
      let foundAction = foundObj?.goal?.actions?.find(
        (action) => action?.id === actionId
      );

      let res = foundObj?.goal?.actions?.filter(
        (action) => action?.id !== actionId
      );

      const modifiedObj = {
        ...foundObj,
        goal: {
          ...foundObj.goal,
          actions: [...res],
        },
      };

      if (foundAction?.isNew) {
        dispatch(SetSelectedGoalAction({ ...modifiedObj, i }));
        setActionPlanData(actionPlanDataCopy);
      } else {
        dispatch(
          DeleteActions(actionId, () =>
            prioritizeActionItems(modifiedObj, goalId)
          )
        );
      }
    }
  };

  const validateDate = (deadline, startline) => {
    let isSame = moment(deadline).isSame(startline);
    if (isSame) return "Startline and Deadline should not same.";
    let validate = moment(deadline).isBefore(startline);
    if (validate) return "Startline must before Deadline.";
  };

  const handleSwitch = (e, id, i) => {
    let dataCopy = [...actionPlanData];
    let findedObj = dataCopy?.find((item) => item?.id === id);

    findedObj = { ...findedObj, is_private: !e };
    dataCopy[i] = findedObj;

    dispatch(SetSelectedGoalAction({ ...findedObj, i }));
    dispatch(UpdateUserArea(findedObj?.id, { is_private: !e }));

    setActionPlanData(dataCopy);
  };

  const arrLength = selectedGoalAction?.goal?.actions?.length;
  const contextRefs = useRef([]);
  const deadlineRefs = useRef([]);
  const deadlineDateRefs = useRef([]);
  const startlineRefs = useRef([]);
  const startlineDateRefs = useRef([]);
  const accRefs = useRef([]);
  const habitRefs = useRef([]);

  if (contextRefs.current.length !== arrLength) {
    contextRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => {
        let data = {
          current: contextRefs.current[i],
          ref: createRef(),
          i,
        };
        return data;
      });
  }
  if (deadlineRefs.current.length !== arrLength) {
    deadlineRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => deadlineRefs.current[i] || createRef());
    deadlineDateRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => deadlineDateRefs.current[i] || createRef());
  }
  if (startlineRefs.current.length !== arrLength) {
    startlineRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => startlineRefs.current[i] || createRef());
    startlineDateRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => startlineDateRefs.current[i] || createRef());
  }
  if (accRefs.current.length !== arrLength) {
    accRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => {
        let data = {
          current: accRefs.current[i],
          ref: createRef(),
          i,
        };
        return data;
      });
  }
  if (habitRefs.current.length !== arrLength) {
    habitRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => {
        let data = {
          current: habitRefs.current[i],
          ref: createRef(),
          i,
        };
        return data;
      });
  }

  const handleFocus = (e, i, type, nextType) => {
    if (e.key === "Tab") {
      e.preventDefault();

      const refs = {
        context: contextRefs,
        accountability: accRefs,
        habit: habitRefs,
        deadlineDate: deadlineDateRefs,
        deadline: deadlineRefs,
        startlineDate: startlineDateRefs,
        startline: startlineRefs,
      };

      /* if (arrLength - 1 === i) {
        refs[type]?.current[i - 1].current.blur();
        refs[nextType]?.current[0].current.focus();
      } else {
        refs[type]?.current[i].current.blur();
        refs[type]?.current[i + 1].current.focus();
      } */
    }
  };

  const handlePrev = () => {
    navigate(PURPOSE_PATH);
  };

  const handleNext = () => {
    const formData = new FormData();
    formData.append("step", 6);
    dispatch(UpdateLastStep(formData));
    navigate(MASTER_PATH);
  };

  const handleAssistantSelect = (actionItems, selectedActionId) => {
    let dataCopy = [...actionPlanData];
    let findedObj = dataCopy?.find(
      (item) => item?.id === selectedGoalAction?.id
    );

    const newActionItems = [];
    const newActionItemsIds = [];

    const createActionAsync = (actionObj, findedObj, dispatch) => {
      return new Promise((resolve) => {
        dispatch(
          CreateAction({ ...actionObj, goal: findedObj?.goal?.id }, (data) => {
            resolve(data);
          })
        );
      });
    };

    const updateActionAsync = (actionId, updatedActionItem, dispatch) => {
      return new Promise((resolve) => {
        dispatch(
          UpdateActions(actionId, { ...updatedActionItem }, false, {
            callback: () => resolve(updatedActionItem),
            showLoader: false,
          })
        );
      });
    };

    const processActions = async (actionItems, findedObj, dispatch) => {
      const actions =
        findedObj?.goal?.actions.filter((action) => !action?.completed) || [];

      let actionIndex = actions.findIndex(
        (action) => action.id === selectedActionId
      );
      let remainingActionItems = [...actionItems];

      // Use empty action items if available
      for (
        let i = actionIndex;
        i < actions.length && remainingActionItems.length > 0;
        i++ /* , j++ */
      ) {
        if (!actions[i]?.context || actions[i]?.id === selectedActionId) {
          let actionItem = remainingActionItems.shift();

          actions[i] = {
            ...actions[i],
            context: actionItem?.text,
            type: actionItem?.type,
            accountability: actionItem?.accountability,
            habit: actionItem?.type === "Habit",
          };

          try {
            await updateActionAsync(actions[i].id, actions[i], dispatch);
          } catch (error) {
            console.error(error);
          }
        }
      }

      // Create new actions for any remaining items
      for (const actionItem of remainingActionItems) {
        let actionObj = {
          context: actionItem?.text,
          type: actionItem?.type,
          accountability: actionItem?.accountability,
          habit: actionItem?.type === "Habit",
        };

        try {
          const data = await createActionAsync(actionObj, findedObj, dispatch);
          newActionItems.push(data);
          newActionItemsIds.push(data.id);
        } catch (error) {
          console.error(error);
        }
      }

      prioritizeActionItems(
        {
          ...findedObj,
          goal: {
            ...findedObj?.goal,
            actions: [...actions, ...newActionItems],
          },
        },
        findedObj?.id,
        { showLoader: false, propagate: true }
      );
    };

    // Call processActions with your actionItems, findedObj, and dispatch function
    processActions(actionItems, findedObj, dispatch);
  };

  const handleToggleCompleteGoal = () => {
    const { goal, id, i } = selectedGoalAction || {};

    const payload = {
      target: {
        value: !goal?.completed,
        name: "completed",
      },
    };

    goal?.completed
      ? handleGoalChange(payload, id, i)
      : handleCompleteGoal(payload, id, i);
  };

  const handleAccountabilitySelect = item => {
    let label = item?.label;

    if (typeof item?.label !== 'string') {
      label = item?.label.props.label;
    }

    handleGoalChange(
      {
        target: {
          value: label,
          name: "accountability",
        },
      },
      selectedGoalAction?.id,
      selectedGoalAction?.i
    );
  }

  const handleAccountabilityDeselect = () => {
    handleGoalChange(
      {
        target: {
          value: "label",
          name: "accountability",
        },
      },
      selectedGoalAction?.id,
      selectedGoalAction?.i
    );
  }

  /* const strategiesOptions = strategies
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    ?.map((acc) => {
      return {
        key: acc?.name,
        element: acc?.name,
      };
    }); */

  const userData = JSON.parse(getCookie("userData"));
  const isLocked =
    selectedGoalAction?.is_private && userData?.id !== selectedGoalAction?.user;

  const goalProgress = selectedGoalAction?.goal?.gap_percentage
    ? Math.ceil(Math.abs(100 - selectedGoalAction?.goal?.gap_percentage))
    : 0;

  let actionsLength = 0;
  let completedActionsLength = 0;

  if (selectedGoalAction?.goal?.actions) {
    actionsLength = selectedGoalAction.goal.actions.length;
    completedActionsLength = selectedGoalAction.goal.actions.filter(
      (action) => action?.completed
    ).length;
  }

  return (
    <StepLayout
      title="6-10. Goal Action Plan"
      progressPosition={isMobile ? "col" : "row"}
      progress={
        isLocked ? (
          <div style={{ minHeight: 38 }} />
        ) : isMobile ? (
          <Styles.ExtraHeader>
            <Tooltip
              placement="leftTop"
              type="tertiary"
              title="Measurements"
              text="Track and quantify progress toward your goals."
              alternative
              align={{
                offset: [0, 6],
              }}
            >
              <Styles.ProgressWrapper $width={window.innerWidth / 2}>
                <Styles.ProgressLabel>Goal Progress</Styles.ProgressLabel>
                <RadialBar value={goalProgress} />
              </Styles.ProgressWrapper>
            </Tooltip>

            <Styles.ProgressWrapper>
              <Styles.ProgressLabel>Plan Progress</Styles.ProgressLabel>
              <RadialBar value={actionsProgress?.progress || "0"} />
            </Styles.ProgressWrapper>
          </Styles.ExtraHeader>
        ) : (
          <>
            <Tooltip
              placement="leftTop"
              type="tertiary"
              title="Measurements"
              text="Track and quantify progress toward your goals."
              alternative
              align={{
                offset: [0, 6],
              }}
            >
              <Styles.ProgressWrapper>
                <Styles.ProgressLabel>Goal Progress</Styles.ProgressLabel>
                <RadialBar value={goalProgress} />
              </Styles.ProgressWrapper>
            </Tooltip>

            <Styles.ProgressWrapper>
              <Styles.ProgressLabel>Plan Progress</Styles.ProgressLabel>
              <RadialBar value={actionsProgress?.progress || "0"} />
            </Styles.ProgressWrapper>

            <Print data={selectedGoalAction} />
          </>
        )
      }
      pagination={
        <Pagination i={selectedGoalAction?.i} actionPlanData={actionPlanData} />
      }
      nextPageTitle="Complete Master Plan"
      isNextDisabled={selectedGoalAction?.i < actionPlanData?.length - 1}
      onPrev={handlePrev}
      onNext={handleNext}
    >
      <AssistantProvider>
        <Styles.Container>
          <Styles.ContainerItem>
            <Styles.TableContainer>
              <Header
                data={selectedGoalAction}
                isPrivate={isLocked}
                onUpdate={handleGoalUpdate}
                onAddMetricOpen={onAddMetricPopupOpenHandle}
                onAddMetricToggle={onManageToggleHandle}
                device={
                  !!isMobile ? "Mobile" : !!isTablet ? "Tablet" : "Desktop"
                }
              />

              <Styles.Planwrapper>
                {isLocked && (
                  <LockedOver width="40px" height={120} display="row" />
                )}
                {!isMobile && !isTablet ? (
                  <>
                    <Styles.Goalsitem>
                      <Styles.Wrapper>
                        <Styles.Content>
                          <Styles.GoalsRow>
                            <Styles.WrapHead>
                              <Styles.GoalHeading>Goal</Styles.GoalHeading>
                              <Styles.GoalText>
                                {selectedGoalAction?.goal?.goal_name}
                              </Styles.GoalText>
                            </Styles.WrapHead>

                            <Styles.ActionsWrapper
                              $action
                              style={{ marginRight: "2vw" }}
                            >
                              <Styles.GoalsCol $date>
                                <Styles.InputLabel>
                                  8. Startline
                                </Styles.InputLabel>
                                <Styles.InputField className="datepicker-wrapper">
                                  <CustomDatepicker
                                    selected={
                                      selectedGoalAction?.goal?.startline
                                        ? moment(
                                            selectedGoalAction?.goal?.startline
                                          ).toDate()
                                        : null
                                    }
                                    maxDate={moment(
                                      selectedGoalAction?.goal?.deadline
                                    )
                                      .subtract(1, "days")
                                      .toDate()}
                                    placeholderText={"Startline"}
                                    onChange={(date) => {
                                      handleGoalChange(
                                        {
                                          target: {
                                            value: moment(date).isValid()
                                              ? moment(date).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : null,
                                            name: "startline",
                                          },
                                        },
                                        selectedGoalAction?.id,
                                        selectedGoalAction?.i
                                      );
                                    }}
                                  />
                                </Styles.InputField>
                              </Styles.GoalsCol>

                              <Styles.GoalsCol $date>
                                <Styles.InputLabel>
                                  9. Deadline
                                </Styles.InputLabel>
                                <Styles.InputField className="datepicker-wrapper">
                                  <CustomDatepicker
                                    selected={
                                      selectedGoalAction?.goal?.deadline
                                        ? moment(
                                            selectedGoalAction?.goal?.deadline
                                          ).toDate()
                                        : null
                                    }
                                    minDate={selectedGoalAction?.goal?.startline
                                      ? moment(
                                        selectedGoalAction?.goal?.startline
                                      ).toDate()
                                      : null}
                                    placeholderText={"Deadline"}
                                    onChange={(date) => {
                                      handleGoalChange(
                                        {
                                          target: {
                                            value: moment(date).isValid()
                                              ? moment(date).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : null,
                                            name: "deadline",
                                          },
                                        },
                                        selectedGoalAction?.id,
                                        selectedGoalAction?.i
                                      );
                                    }}
                                  />
                                </Styles.InputField>
                              </Styles.GoalsCol>
                            </Styles.ActionsWrapper>

                            <Styles.GoalsCol $accountability>
                              <Styles.InputLabel>
                                10. Accountability
                              </Styles.InputLabel>
                              <AccountabilitySelect
                                options={getGroupedOptions(accountabilityData, customAccountabilityData, handleAccountabilityDeselect)}
                                value={getOptionWithLabel(selectedGoalAction?.goal?.accountability, accountabilityData, customAccountabilityData) || undefined}
                                onSelect={handleAccountabilitySelect}
                                onCreate={handleAccountabilityCreate}
                              />
                            </Styles.GoalsCol>

                            <Styles.GoalsCol $space />

                            <Styles.GoalsCol $habit>
                              {SelectedClient ? (
                                <Styles.TogglePlaceholder />
                              ) : (
                                <Toggle
                                  id="private_goal_toggle"
                                  checked={selectedGoalAction?.is_private}
                                  label="PRIVATE"
                                  labelFirst
                                  size="sm"
                                  onChange={() =>
                                    handleSwitch(
                                      selectedGoalAction?.is_private,
                                      selectedGoalAction?.id,
                                      selectedGoalAction?.i
                                    )
                                  }
                                />
                              )}

                              <Dropdown
                                secondary
                                menuItems={[
                                  {
                                    key: "private",
                                    element: (
                                      <Styles.DropdownItem>
                                        Complete Goal
                                        <Checkbox
                                          checked={
                                            selectedGoalAction?.goal?.completed
                                          }
                                          readOnly
                                        />
                                      </Styles.DropdownItem>
                                    ),
                                    onClick: () => handleToggleCompleteGoal(),
                                  },
                                ]}
                              />
                            </Styles.GoalsCol>
                          </Styles.GoalsRow>
                        </Styles.Content>
                      </Styles.Wrapper>
                    </Styles.Goalsitem>

                    <Styles.Step1 isWidth={true} isMargin={true}>
                      <Styles.RowWrapper>
                        <Styles.HeadCol $actions $width={"calc(28% + 80px)"}>
                          <Styles.Item style={{ flex: 1 }}>
                            6. Enter Actions
                          </Styles.Item>
                          <Styles.Item style={{ width: 80 }}>
                            7. Prioritize
                          </Styles.Item>
                        </Styles.HeadCol>

                        <Styles.HeadCol $deadline>
                          <Styles.Item style={{ width: "7vw" }}>
                            8. Startline
                          </Styles.Item>
                          <Styles.Item style={{ width: "7vw" }}>
                            9. Deadline
                          </Styles.Item>
                        </Styles.HeadCol>

                        <Styles.HeadCol
                          $width={isLargTablet ? "20%" : "14%"}
                          style={{ marginRight: 20 }}
                        >
                          <Styles.Item>10. Accountability</Styles.Item>
                        </Styles.HeadCol>

                        <Styles.HeadCol $width="140px">
                          <Styles.Item $type>
                            Select Type <TypeTooltip />
                          </Styles.Item>
                        </Styles.HeadCol>

                        <Styles.HeadCol $width="51px" $last></Styles.HeadCol>
                      </Styles.RowWrapper>
                    </Styles.Step1>
                  </>
                ) : (
                  <>
                    <Styles.Goalsitem>
                      <Styles.Wrapper>
                        <Styles.Content>
                          <Styles.GoalsRow>
                            <Styles.WrapHead>
                              <Styles.GoalHeading>Goal</Styles.GoalHeading>
                              <Styles.GoalsCol $habit>
                                {SelectedClient ? (
                                  <Styles.TogglePlaceholder />
                                ) : (
                                  <Toggle
                                    id="private_goal_toggle"
                                    checked={selectedGoalAction?.is_private}
                                    label="PRIVATE"
                                    labelFirst
                                    size="sm"
                                    onChange={() =>
                                      handleSwitch(
                                        selectedGoalAction?.is_private,
                                        selectedGoalAction?.id,
                                        selectedGoalAction?.i
                                      )
                                    }
                                  />
                                )}

                                <Dropdown
                                  secondary
                                  menuItems={[
                                    {
                                      key: "private",
                                      element: (
                                        <Styles.DropdownItem>
                                          Complete Goal
                                          <Checkbox
                                            checked={
                                              selectedGoalAction?.goal
                                                ?.completed
                                            }
                                            readOnly
                                          />
                                        </Styles.DropdownItem>
                                      ),
                                      onClick: () => handleToggleCompleteGoal(),
                                    },
                                  ]}
                                />
                              </Styles.GoalsCol>
                            </Styles.WrapHead>
                            <Styles.GoalText>
                              {selectedGoalAction?.goal?.goal_name}
                            </Styles.GoalText>
                            <Styles.ActionsWrappers>
                              <Styles.ActionsWrapper style={{ width: isTablet ? '40%' : '100%'}} $action>
                                <Styles.GoalsCol $date>
                                  <Styles.InputLabel>
                                    8. Startline
                                  </Styles.InputLabel>
                                  <Styles.InputField $leftAlign className="datepicker-wrapper">
                                    <CustomDatepicker
                                      selected={
                                        selectedGoalAction?.goal?.startline
                                          ? moment(
                                              selectedGoalAction?.goal
                                                ?.startline
                                            ).toDate()
                                          : null
                                      }
                                      maxDate={moment(
                                        selectedGoalAction?.goal?.deadline
                                      )
                                        .subtract(1, "days")
                                        .toDate()}
                                      placeholderText={"Startline"}
                                      onChange={(date) => {
                                        handleGoalChange(
                                          {
                                            target: {
                                              value: moment(date).isValid()
                                                ? moment(date).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                : null,
                                              name: "startline",
                                            },
                                          },
                                          selectedGoalAction?.id,
                                          selectedGoalAction?.i
                                        );
                                      }}
                                    />
                                  </Styles.InputField>
                                </Styles.GoalsCol>

                                <Styles.GoalsCol $date>
                                  <Styles.InputLabel>
                                    9. Deadline
                                  </Styles.InputLabel>
                                  <Styles.InputField $leftAlign  className="datepicker-wrapper">
                                    <CustomDatepicker
                                      selected={
                                        selectedGoalAction?.goal?.deadline
                                          ? moment(
                                              selectedGoalAction?.goal?.deadline
                                            ).toDate()
                                          : null
                                      }
                                      minDate={selectedGoalAction?.goal?.startline
                                        ? moment(
                                          selectedGoalAction?.goal?.startline
                                        ).toDate()
                                        : null}
                                      placeholderText={"Deadline"}
                                      onChange={(date) => {
                                        handleGoalChange(
                                          {
                                            target: {
                                              value: moment(date).isValid()
                                                ? moment(date).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                : null,
                                              name: "deadline",
                                            },
                                          },
                                          selectedGoalAction?.id,
                                          selectedGoalAction?.i
                                        );
                                      }}
                                    />
                                  </Styles.InputField>
                                </Styles.GoalsCol>
                              </Styles.ActionsWrapper>

                              <Styles.GoalsCol $accountability>
                                <Styles.InputLabel>
                                  10. Accountability
                                </Styles.InputLabel>
                                <AccountabilitySelect
                                  options={getGroupedOptions(accountabilityData, customAccountabilityData, handleAccountabilityDeselect)}
                                  value={getOptionWithLabel(selectedGoalAction?.goal?.accountability, accountabilityData, customAccountabilityData) || undefined}
                                  onSelect={handleAccountabilitySelect}
                                  onCreate={handleAccountabilityCreate}
                                />
                              </Styles.GoalsCol>
                            </Styles.ActionsWrappers>
                            <Styles.GoalsCol $space />
                          </Styles.GoalsRow>
                        </Styles.Content>
                      </Styles.Wrapper>
                    </Styles.Goalsitem>
                  </>
                )}
                <Styles.DragDropContainer>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`${selectedGoalAction?.id}`}>
                      {(provided) => (
                        <Styles.Wrapper
                          className="characters"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {selectedGoalAction?.goal?.actions
                            ?.sort((a, b) => a.priority - b.priority)
                            ?.filter((action) => !action?.completed)
                            ?.map((action, actionIndex) => (
                              <DraggableActionItem
                                draggableId={action.id}
                                index={actionIndex}
                                action={action}
                                actionIndex={actionIndex}
                                selectedGoalAction={selectedGoalAction}
                                accountabilities={accountabilityData}
                                customAccountabilities={customAccountabilityData}
                                // strategies={strategies}
                                domainId={masterPlanDetails?.journey?.id}
                                canBeDeleted={
                                  selectedGoalAction?.goal?.actions?.filter(
                                    (action) => !action?.completed
                                  ).length > 2
                                }
                                onChange={handleChange}
                                onAccountabilityCreate={handleAccountabilityCreate}
                                onFocus={handleFocus}
                                onMenuClick={onMenuClick}
                                onAssistantSelect={handleAssistantSelect}
                                ref={{
                                  contextRefs,
                                }}
                                key={action.id}
                              />
                            ))}

                          {provided.placeholder}

                          <Styles.PlanwrapperHeading>
                            <Toggle
                              id="completed_actions_toggle"
                              checked={showCompletedActions}
                              label="SHOW COMPLETED ACTION ITEMS"
                              size="sm"
                              onChange={(e) =>
                                setShowCompletedActions(e.target.checked)
                              }
                            />
                            {/* <Checkbox
                              onChange={(e) =>
                                setShowCompletedActions(e.target.checked)
                              }
                              checked={showCompletedActions}
                            >
                              Show Completed Actions
                            </Checkbox> */}

                            <Styles.AddButton
                              type="button"
                              disabled={loading || masterplanLoading}
                              onClick={() =>
                                handleAddMore(
                                  selectedGoalAction?.id,
                                  selectedGoalAction?.i
                                )
                              }
                            >
                              <img src={plusIcon} alt="+" />
                              Action Item
                            </Styles.AddButton>
                          </Styles.PlanwrapperHeading>
                        </Styles.Wrapper>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Styles.DragDropContainer>


                {showCompletedActions && (
                  <Styles.CompletedActionsList>
                    {selectedGoalAction?.goal?.actions
                      ?.filter((action) => action?.completed)
                      ?.map((action, actionIndex) => (
                        <ActionItem
                          isDraggable={false}
                          $color={selectedGoalAction?.color}
                          $isHighlighted={
                            (actionIndex === 0 || actionIndex === 1) &&
                            !action?.completed
                          }
                          actionIndex={actionIndex}
                          action={action}
                          selectedGoalAction={selectedGoalAction}
                          canBeDeleted
                          onMenuClick={onMenuClick}
                          ref={{
                            contextRefs,
                          }}
                          key={action.id}
                        />
                      ))}
                  </Styles.CompletedActionsList>
                )}
              </Styles.Planwrapper>

              <Styles.Disclaimer>
                NOTE: First two action items should be reflected on your Master
                Plan
              </Styles.Disclaimer>

              <PlanShortcut
                isVisible={masterPlanDetails?.viewed_masterplan_screen}
              />
            </Styles.TableContainer>
          </Styles.ContainerItem>
        </Styles.Container>
      </AssistantProvider>

      <CompleteActionItemModal
        setOpen={setOpen}
        open={open}
        onComplete={handleCompleteAction}
      />

      <CreateMetricsPopup
        active={isAddCustomMetric}
        onClose={onAddMetricPopupCloseHandle}
      />

      <ManageMetricsPopup
        active={isManageMetric}
        onClose={onManageToggleHandle}
      />
    </StepLayout>
  );
};

export default Actions;