import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColumnChart from '../ColumnChart';
import JourneyDetails from '../JourneyDetails';
import AreaDetails from '../AreaDetails';
import Progress from '../Progress';

import {
  Wrapper,
  Content,

  Header,
  Title,

  Legend,
  LegendItem,
  
  Side,
  SideHr,
  ProgressWrapper
} from './styled';

import { useQueryParams } from 'utils/useQueryParams';

import { GetAreasProgress } from 'Redux/Achievements/ListAreasProgress/action';

import { IState } from '@types';
import DetailsWrapper from '../DetailsWrapper';

const DetailedTrends = () => {
  const dispatch = useDispatch();
  const { journey } = useQueryParams();

  const { domainList } = useSelector((state: IState) => state.domainListReducer);
  const { data: journeysProgress } = useSelector((state: IState) => state.listMasterProgress);
  const { data: areasProgress } = useSelector((state: IState) => state.listAreasProgress);
  const { data: overallProgress } = useSelector((state: IState) => state.listOverallProgress);

  const { goal_progress, plan_progress } = overallProgress;

  const [masterPlanId, setMasterPlanId] = useState<string | null>(null);

  useEffect(() => {
    const domain = domainList.find((d: any) => d.id === journey);

    if (domain) {
      const masterPlanId = domain.current_state?.id;
      setMasterPlanId(masterPlanId);
    };
  }, [domainList, journey]);

  useEffect(() => {
    if (masterPlanId) {
      dispatch(GetAreasProgress({
        domain: masterPlanId
      }));
    }
  }, [masterPlanId]);

  const data = journey
    ? areasProgress.map((item) => ({
        id: item.user_area_name,
        label: item.user_area_name,
        goal: item.goal_progress,
        plan: item.plan_progress
    }))
    : journeysProgress.map((item) => ({
        id: item.masterplan_id,
        label: item.masterplan_name,
        goal: item.goal_progress,
        plan: item.plan_progress
    }));

  return (
    <Wrapper>
      <Content>
        <Header>
          <Title>{journey ? "Area" : "Journey"} Trends</Title>

          <Legend>
            <LegendItem $goal $alt={!!journey}>Goal Completion Rate</LegendItem>
            <LegendItem $plan $alt={!!journey}>Plan Progress</LegendItem>
          </Legend>
        </Header>

        <ColumnChart data={data} />
      </Content>

      <Side>
        <ProgressWrapper>
          <Progress
            type="goal"
            style={journey ? "secondary" : "primary"}
            title="Goal Completion Rate"
            value={goal_progress.progress % 1 === 0 ? goal_progress.progress : goal_progress.progress.toFixed(1)}
            text={`${goal_progress.completed_goals}/${goal_progress.total_goals} Goals Completed`}
          />
          <SideHr />
          <Progress
            type="plan"
            style={journey ? "secondary" : "primary"}
            title="Plan Progress"
            value={plan_progress.progress % 1 === 0 ? plan_progress.progress : plan_progress.progress.toFixed(1)}
            text={`${plan_progress.completed_actions}/${plan_progress.total_actions} Actions complete`}
          />
        </ProgressWrapper>

        <DetailsWrapper data={data}>
          {journey
            ? <AreaDetails masterPlanId={masterPlanId} />
            : <JourneyDetails />}
        </DetailsWrapper>
      </Side>
    </Wrapper>
  );
}

export default DetailedTrends;
