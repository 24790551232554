import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Wrapper,
  Content
} from './styled';

import Header from './Header';

{/* TODO. Hidden because no backend logic yet */}
// import OverallTrend from './OverallTrend';

import DetailedTrends from './DetailedTrends';

// @ts-ignore
import { ListDomain } from "Redux/ListDomains/action";

import { GetOverallProgress } from "Redux/Achievements/ListOverallProgress/action";
import { GetMasterProgress } from 'Redux/Achievements/ListMasterProgress/action';

import { useQueryParams } from 'utils/useQueryParams';

interface IState {
  domainListReducer: {
    domainList: any;
  }
}

const Achievements = () => {
  const dispatch = useDispatch();

  const { journey: journeyId, area: areaName } = useQueryParams();

  const { domainList } = useSelector((state: IState) => state.domainListReducer);

  const [masterPlanId, setMasterPlanId] = useState<string | null>(null);

  useEffect(() => {
    dispatch(ListDomain());
    dispatch(GetMasterProgress());
  }, []);
  
  useEffect(() => {
    const domain = domainList.find((d: any) => d.id === journeyId);

    if (domain) {
      const masterPlanId = domain.current_state?.id;
      setMasterPlanId(masterPlanId);
    };
  }, [domainList, journeyId]);

  useEffect(() => {
    dispatch(GetOverallProgress({
      ...(masterPlanId && { domain: masterPlanId }),
      ...(areaName && { area: areaName })
    }));
  }, [masterPlanId]);

  return (
    <Wrapper>
      <Header />

      <Content>
        {/* TODO. Hidden because no backend logic yet */}
        {/* <OverallTrend /> */}

        <DetailedTrends />
      </Content>
    </Wrapper>
  );
}

export default Achievements;
