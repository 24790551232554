import styled, { css } from 'styled-components';

export const Toggle = styled.button`
  width: 172px;
  height: 36px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--Stroke-Text-field-Gainsboro, #E3E5E9);
  background: var(--Fill-Text-field-White, #FFF);
  color: #1B2A3D;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface IIcon {
  $lg?: boolean;
}

export const Icon = styled.img<IIcon>`
  width: 20px;
  height: 20px;

  ${({ $lg }) => $lg && css`
    width: 24px;
    height: 24px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  max-height: 300px;
  padding: 20px 10px 20px 20px;
  border-radius: 6px;
  border: 1px solid #E3E5E9;
  background: #FFF;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Title = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

export const Close = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
  padding: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Option = styled.button`
  border: none;
  text-align: left;
  padding: 0;
  height: initial;
  background: transparent;
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;
