// @ts-ignore
import { getAreasProgress } from "api";
import Notify from "utils/notify";

import { ActionTypes } from "./types";

interface IPayload {
  domain?: string;
}

export const GetAreasProgress = (payload = {} as IPayload, onlyFetch?: boolean, callback?: (data: any) => void) => async (dispatch: any) => {
  try {
    if (!onlyFetch) {
      dispatch({ type: ActionTypes.LOADING });
    }

    const filtersParams: { [key: string]: any } = {};

    if (payload?.domain) {
      filtersParams['masterplan_id'] = payload?.domain;
    }

    const { data } = await getAreasProgress(filtersParams);

    if (callback) {
      callback(data);
    }

    if (!onlyFetch) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
