export const ActionTypes = {
  LOADING: "CATEGORIES_LOADING",
  ERROR: "CATEGORIES_ERROR",
  SUCCESS: "CATEGORIES_SUCCESS",
  CUSTOM_SUCCESS: "CATEGORIES_CUSTOM_SUCCESS",

  LOADING_CREATE: "CATEGORY_LOADING_CREATE",
  SUCCESS_CREATE: "CATEGORY_SUCCESS_CREATE",
  ERROR_CREATE: "CATEGORY_ERROR_CREATE",

  LOADING_DELETE: "CATEGORY_LOADING_DELETE",
  SUCCESS_DELETE: "CATEGORY_SUCCESS_DELETE",
  ERROR_DELETE: "CATEGORY_ERROR_DELETE",
};

export interface IAction {
  type: string;
  payload: any;
}
