import { ActionTypes, IAction } from "./types";

let INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
  custom: [],
};

export default (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        listCategories: action.payload,
      };

    case ActionTypes.CUSTOM_SUCCESS:
      return {
        ...state,
        loading: false,
        custom: action.payload,
      };

    case ActionTypes.SUCCESS_CREATE:
      return {
        ...state,
        loading: false,
        custom: [
          ...state.custom,
          action.payload
        ]
      };

    case ActionTypes.SUCCESS_DELETE:
      return {
        ...state,
        loading: false,
        custom: state.custom.filter((item: any) => item.id !== action.payload)
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
