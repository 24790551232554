import { FC, ReactNode, useEffect, useState } from 'react';

import {
  Container,
  Header,
  Wrapper,
  Content,
  Hr,

  Title,
  Icon,

  Chart,
  ChartHead,
  ChartBody,
  BarWrap,
  Bar,
  BarValue,
  ChartFooter,
  PagiButton,
  CurrentPage,
  Pagination,
  Dots,
  Dot
} from './styled';

// @ts-ignore
import arrowDownIcon from "assets/images/achievements/arrow-down.svg";

import { useQueryParams } from 'utils/useQueryParams';
import useWindowSize from 'utils/useWindowSize';

// @ts-ignore
import { BREAKPOINTS } from "theme/mixins";

interface IData {
  id: string;
  label: string;
  goal: number;
  plan: number;
}

interface IProps {
  data: IData[];
  children: ReactNode;
}

const DetailsWrapper: FC<IProps> = ({ data, children }) => {
  const [item, setItem] = useState<IData | null>(null);

  const { journey, trend: trendIndex, setQueryParams } = useQueryParams();
  const { width } = useWindowSize();

  useEffect(() => {
    const dataItem = data[parseInt(trendIndex || '1') - 1] as IData || null;

    if (dataItem) {
      setItem(dataItem);
    }
  }, [trendIndex, data]);

  const handlePrevClick = () => {
    const index = Math.max(1, parseInt(trendIndex || '1') - 1);

    const filters = {
      trend: index.toString()
    };

    setQueryParams(filters);
  }

  const handleNextClick = () => {
    const index = Math.min(data.length, parseInt(trendIndex || '1') + 1);

    const filters = {
      trend: index.toString()
    };

    setQueryParams(filters);
  }

  const isMobileView = !!(width && width < BREAKPOINTS.sm);

  if (!item)
    return null;

  if (!isMobileView)
    return null;

  const { label, goal: goalProgress, plan: planProgress } = item;

  return (
    <Container>
      {isMobileView ? (<Header>{journey ? "Area" : "Journey"} Trends</Header>) : null}

      <Wrapper>
        {isMobileView ? (
          <>
            <Chart>
              <ChartHead>
                <Title>{item.label}</Title>
              </ChartHead>

              <ChartBody>
                <BarWrap>
                  <Bar $type="plan" $width={goalProgress} />
                  <BarValue $type="plan">{parseFloat(goalProgress.toFixed(1))}%</BarValue>
                </BarWrap>

                <BarWrap>
                  <Bar $type="goal" $width={planProgress} />
                  <BarValue $type="goal">{parseFloat(planProgress.toFixed(1))}%</BarValue>
                </BarWrap>
              </ChartBody>
            </Chart>

            <Hr />
          </>
        ) : null}

        <Content>
          {children}
        </Content>
      </Wrapper>

      <ChartFooter>
        <Dots>
          {data.map((_, index) => (
            <Dot key={index} $active={parseInt(trendIndex || '1') === index + 1} />
          ))}
        </Dots>

        <Pagination>
          <PagiButton disabled={parseInt(trendIndex || '1') <= 1} onClick={handlePrevClick}>
            <Icon $rotate={90} src={arrowDownIcon} alt="" />
          </PagiButton>

          <CurrentPage>{label}</CurrentPage>

          <PagiButton disabled={parseInt(trendIndex || '1') >= data.length} onClick={handleNextClick}>
            <Icon $rotate={-90} src={arrowDownIcon} alt="" />
          </PagiButton>
        </Pagination>
      </ChartFooter>
    </Container>
  );
}

export default DetailsWrapper;
