// @ts-ignore
import { getMasterProgress } from "api";
import Notify from "utils/notify";

import { ActionTypes } from "./types";

interface IPayload {
  domain?: string;
}

export const GetMasterProgress = (payload = {} as IPayload) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const filtersParams: { [key: string]: any } = {};

    if (payload?.domain) {
      filtersParams['masterplan_id'] = payload?.domain;
    }

    const { data } = await getMasterProgress(filtersParams);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
