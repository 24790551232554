// @ts-ignore
import { getAreaStatuses } from "api";
import Notify from "utils/notify";

import { ActionTypes } from "./types";

interface IPayload {
  domain?: string;
  area?: string;
}

export const GetAreaStatuses = (payload = {} as IPayload) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const filtersParams: { [key: string]: any } = {};

    if (payload?.domain) {
      filtersParams['masterplan_id'] = payload?.domain;
    }

    if (payload?.area) {
      filtersParams['user_area_name'] = payload?.area;
    }

    const { data } = await getAreaStatuses(filtersParams);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    // Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
