import styled from "styled-components";

export const Content = styled.div<{ $color?: string }>`
  display: flex;
  flex-direction: column;
  width: 450px;
  border-radius: 13px;
  background: var(--Dark-mode-bg, #FFF);
  padding: 16px;
  background: #fff;
  box-shadow: 0px 0 50px 0px rgba(0, 0, 0, 0.25);

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
  padding-bottom: 12px;
}`;

export const HeaderTitle = styled.span`
  color: var(--Header, #112538);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
}`;

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg .st0 {
    fill: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  }

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const Title = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 17px;
`;

export const Text = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
}`;

export const Footer = styled.div`
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}`;
