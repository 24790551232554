import styled, { css } from 'styled-components';

const colors = {
  primary: {
    goal: '#17C1D9',
    plan: '#FF5733',
  },
  secondary: {
    goal: '#17C1D9',
    plan: '#7400CF',
  }
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  padding-top: 20px;

  {/* TODO. Adjusted because no backend logic yet to display overall chart */}
  // border-top: 1px solid rgba(229, 231, 235, 0.50);

  ${({ theme }) => theme.max("lg")`
    flex-direction: column;
    padding: 0 35px;
    gap: 0;
  `}

  ${({ theme }) => theme.max("sm")`
    padding: 0 20px;
  `}
`;

export const Content = styled.div`
  flex: 1;

  {/* TODO. Adjusted because no backend logic yet to display overall chart */}
  /* padding: 0 34px;

  ${({ theme }) => theme.max("lg")`
    padding: 24px 0 0 0;
  `} */
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  ${({ theme }) => theme.max("sm")`
    display: none;
  `}
`;

export const Legend = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-top: 6px;
`;

interface ILegendItem {
  $goal?: boolean;
  $plan?: boolean;
  $alt?: boolean;
}

export const LegendItem = styled.span<ILegendItem>`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #6B7280;
  font-family: Inter;
  font-size: 12px;
  line-height: 150%;

  ::before {
    content: '';
    width: 10px;
    height: 2px;
    border-radius: 6px;

    ${({ $goal, $alt }) => $goal && css`
      background: ${$alt ? colors.secondary.goal : colors.primary.goal};
    `}
    
    ${({ $plan, $alt }) => $plan && css`
      background: ${$alt ? colors.secondary.plan : colors.primary.plan};
    `}
  }
`;

export const Title = styled.span`
  color: #232323;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
`;

export const Side = styled.div`
  width: 320px;

  ${({ theme }) => theme.max("lg")`
    width: initial;
  `}
`;

export const SideHr = styled.div`
  display: flex;
  margin: 20px 0;
  padding: 0 16px;

  ::before {
    content: '';
    background: rgba(229, 231, 235, 0.50);
    width: 100%;
    height: 1px;
  }

  ${({ theme }) => theme.max("sm")`
    display: none;
  `}
`;

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  ${({ theme }) => theme.max("sm")`
    flex-direction: row;
    align-items: center;
    gap: 24px;
  `}
`;
