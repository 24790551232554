import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import { Avatar, Col, Row } from "antd";

import LogoImg from "../../assets/images/logo.svg";
import BgImg from "../../assets/images/final_step_bg.png";
import Card from "../../components/Card";
import img1 from "../../assets/images/card_img2.png";
import img2 from "../../assets/images/card_img1.png";
import img3 from "../../assets/images/card_img3.png";
import Owner from "../../assets/images/user-rich-bello.png";

import { JOURNEY_PATH, MASTER_PATH } from "utils/routes";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-image: url(${BgImg});
  background-size: cover;
`;

const MainContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  background: #ECF3F6;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
`;

const Logo = styled.img``;

const ThankyouDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`;

const Heading = styled.h5`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;

const SubHeading = styled.h4`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  max-width: 805px;
  flex-wrap: wrap;
  ${({ theme }) => theme.max("md")`
  padding:0 1rem;
`}
`;

const GoalHeading = styled.p`
  padding-top: 0.05rem;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 600;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  max-width: 800px;
  flex-wrap: wrap;
  text-align: center;
`;

const ColWrapper = styled.div`
  width: 80%;
  margin: auto;
  ${({ theme }) => theme.max("lg")`
  width: 90%;
`}
  ${({ theme }) => theme.max("sm")`
  display:none;
`}
`;
const ColWrapperMobile = styled.div`
  display: none;
  ${({ theme }) => theme.max("sm")`
  display:flex;
  flex-direction:row;
  position: relative;
   padding: 40px 10px;
   padding-top:0px;
 & .rec-arrow {
      background-color: transparent !important;
      border: 1px solid #4b545b !important;
      min-width: 30px;
      height: 30px;
      width: 30px;
      display: flex;
      color: #4b545b !important;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      cursor: pointer;
    }
    & .rec-arrow-left {
      position: absolute;
      bottom: -5px;
      right: 70px;
    }
    & .rec-arrow-right {
      position: absolute;
      bottom:-5px;
      right: 25px;
    }
`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 6rem;
  ${({ theme }) => theme.max("sm")`
  flex-direction:column;
  align-items:center;
`}
`;

const Container = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CEODetail = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 40%;
`;

const Designation = styled.h3`
  padding-left: 0.5rem;
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  & span {
    color: #116595;
  }
`;

const SightHeading = styled(GoalHeading)`
  padding-top: 2rem;
  padding-bottom: 0;
  margin: 0;
`;

const ActionButton = styled.button`
  padding: 0;
  width: 165px;
  height: 36px;
  font-family: "Inter";
  color: #1271a6;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  background: transparent;
  outline: none;
  border: 1px solid #1271a6;
  border-radius: 3px;
  margin: 0 6px;
`;

const PrimaryButton = styled.button`
  padding: 0;
  width: 165px;
  height: 36px;
  font-family: "Inter";
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  background: linear-gradient(161.19deg, #1271A6 18.35%, #1271A6 74.45%);
  border-radius: 3px;
  outline: none;
  border: none;
  margin: 0 6px;

  ${({ theme }) => theme.max("sm")`
    margin:0.68rem 0;
  `}
`;

const CardWrap = styled.div`
  height: 100%;

  ${({ theme }) => theme.max("lg")`
    display: flex;
    flex-direction: column;
  `}

  ${({ theme }) => theme.max("sm")`
    display: flex;
    flex-direction: row;
  `}
`;

const CardWrapMob = styled.div`
  ${({ theme }) => theme.max("sm")`
    display: flex;
    flex-direction: column;
  `}
`;

const externalStyle = {
  marginTop: 10
};

const FinalStep = () => {
  const navigate = useNavigate();
  const CardData = [
    {
      src: img1,
      heading: "Executive Coaching",
      description:
        "Build your discipline muscles with a coach. Our coaches motivate you to take action when the going gets tough. Step it up with a coach by your side. ",
      link: "https://arootah.com/executive-coaching/",
    },
    {
      src: img2,
      heading: "Habit Accountability Tracker App",
      description:
        "Keep up the momentum! Stay on top of your goals with daily steps forward with our Habit Accountability Tracker App. ",
      link: "https://app.arootah.com/",
    },
    {
      src: img3,
      heading: "Business Advisory",
      description:
        "Unlock barriers and discover best practices for Hedge Funds and Family Offices. Come to us with your pressing Investments and Operations challenges. ",
      link: "https://arootah.com/business-consulting",
    },
  ];

  return (
    <Wrapper>
      <MainContainer>
        <LogoWrapper>
          <Logo src={LogoImg} alt="logo" />
        </LogoWrapper>
        <ThankyouDetail>
          <Heading>Thank you!</Heading>
          <SubHeading>
            Congratulations on digging deep and setting your goals. Never forget
            that these goals are important to you. You deserve to live the life
            of your dreams. Now, is time to live the successful life you’re
            capable of.
          </SubHeading>
          <SubHeading>
            Goals that change your life or business only get done with fierce
            proactivity. Procrastination causes people a tremendous amount of
            internal pain. It's not that they don't realize their dreams, they
            just never start. Don’t let that be you.
          </SubHeading>
          <SubHeading>
            But here’s the good news: ACCOUNTABILITY is your secret weapon to
            overcoming obstacles, setbacks, and distractions.
          </SubHeading>
        </ThankyouDetail>
        <GoalHeading>
          Here is how Arootah provides you with the accountability to do your
          ambitious goals.
        </GoalHeading>
        <ColWrapper>
          <Row>
            {CardData?.map((item) => (
              <Col xl={8} lg={24} md={24} style={externalStyle} >
                <CardWrap>
                  <Card
                    src={item?.src}
                    description={item?.description}
                    heading={item?.heading}
                    link={item?.link}
                  />
                </CardWrap>
              </Col>
            ))}
          </Row>
        </ColWrapper>
        <ColWrapperMobile>
          <Carousel pagination={false}>
            {CardData?.map((item) => (
              <Row>
                <Col md={24} sm={24}>
                  <CardWrapMob>
                    <Card
                      src={item?.src}
                      description={item?.description}
                      heading={item?.heading}
                      link={item?.link}
                    />
                  </CardWrapMob>
                </Col>
              </Row>
            ))}
          </Carousel>
        </ColWrapperMobile>
        <Container>
          <SubHeading>
            “No matter how big your personal goals are in {moment().year()}, you can crush
            them! But it won’t happen on its own... you need to make it happen.
            And Arootah will pull you up to do just that!”
          </SubHeading>
          <CEODetail>
            <Avatar src={Owner} size={32} />
            <Designation>
              <span>Rich Bello</span> | CEO of Arootah
            </Designation>
          </CEODetail>
          <SightHeading>Out of Sight, Out of Mind</SightHeading>
          <SubHeading className="mb-0">
            Log back in anytime to review your Master Plan throughout the year.
          </SubHeading>
          {/* <SubHeading className="mt-2">
            Did you sign in as a guest?
            <br /> Create an account to save your goals and Master Plan!
          </SubHeading> */}
        </Container>
        <ButtonWrapper>
          <ActionButton
            onClick={() => {
              navigate(MASTER_PATH);
            }}
          >
            Back to Master Plan
          </ActionButton>
          <PrimaryButton
            onClick={() => {
              navigate(JOURNEY_PATH);
            }}
          >
            Journey
          </PrimaryButton>
        </ButtonWrapper>
      </MainContainer>
    </Wrapper>
  );
};

export default FinalStep;
