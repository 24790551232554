import { FC, MouseEvent } from 'react';

// @ts-ignore
import trashIcon from "assets/svg/trash-outlined.svg";

import { Wrapper, Button } from './styled';

interface IProps {
  value: string;
  label: string;
  disabled: boolean;
  onDelete: (value: string) => void;
}

const CustomOption: FC<IProps> = ({ value, label, disabled, onDelete }) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onDelete(value);
  }

  return (
    <Wrapper>
      <span>{label}</span>

      <Button onClick={handleClick} title={disabled ? "Cannot delete selected item" : "Delete"} disabled={disabled}>
        <img src={trashIcon} alt="" />
      </Button>
    </Wrapper>
  );
}

export default CustomOption;
