import { FC } from 'react';

import {
  Wrapper,
  Head,
  Title,
  Icon,
  Body,
  Content,
  Percent,
  Text,
  Bar,
} from './styled';

// @ts-ignore
import goalIcon from "assets/images/achievements/crisis_alert.svg";

// @ts-ignore
import planIcon from "assets/images/achievements/checklist.svg";

// @ts-ignore
import planAltIcon from "assets/images/achievements/checklist-alt.svg";

interface IProps {
  type: 'goal' | 'plan';
  style: 'primary' | 'secondary';
  title: string;
  value: string | number;
  text: string;
}

const Progress: FC<IProps> = ({ type, style, title, value, text }) => {
  return (
    <Wrapper>
      <Head>
        <Title>{title}</Title>
        <Icon src={type === 'goal' ? goalIcon : [style === 'primary' ? planIcon : planAltIcon]} alt="" />
      </Head>
      <Body>
        <Content>
          <Percent>{value}%</Percent>
          <Text>{text}</Text>
        </Content>
        <Bar $type={type} $alt={style === 'secondary'} $value={value} />
      </Body>
    </Wrapper>
  );
}

export default Progress;
