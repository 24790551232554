import { Dropdown, Menu } from "antd";
import styled , { css } from "styled-components";
import LogoImg from "../../assets/images/header_logo_modern.svg";
import LogoImgMobile from "../../assets/images/short_logo.svg";
import apmLogoImg from "assets/images/apm-logo-v2.svg";
import DrawerIcon from "assets/images/sidebar/drawer.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "Redux/Logout/action";
import { getCookie, removeCookie, setCookie } from "utils/StorageVariables";
import SchedulerSideNav from "../Scheduler/SideNav";

import Home_Icon from "assets/images/home_icon.svg";
import Search from "assets/images/search.png";
import Chevron from "assets/images/Chevron-Down.svg";
import ProfileAvatar from "assets/images/profile/avatar.svg";

import { GetMyClients } from "Redux/MyClients/action";
import { useToken, userTypes } from "utils/utility";
// import { ListDomain } from "Redux/ListDomains/action";
import { setSelectedClient, DrawerMenuAction } from "Redux/Global/action";
import {
  APM_PATH,
  HIERARCHY_PATH,
  HOME_PATH,
  WSH_PATH,
  PROFILE_PATH,
  JOURNEY_PATH,
  COACHING_PATH,
  ACHIEVEMENTS_PATH,
} from "utils/routes";
import AppSwitcher from "components/AppSwitcher";
import { RetrieveUserData } from "Redux/RetrieveUserData/action";
import { ReactComponent as MenuIcon } from "../../assets/images/priorities/menuIcon.svg";

import useDeviceType from "../../Hooks/ResponsiveHook";

const SearchUserInput = ({ id, value, onChange }) => {
  return (
    <InputSearchUser id={id}>
      <Icon id={id} src={Search} alt="search" />
      <UserInput
        id={id}
        value={value}
        placeholder="Search for user"
        onChange={onChange}
      />
    </InputSearchUser>
  );
};

const Header = () => {
  const [SearchUser, setSearchUser] = useState();
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const [userDetails, setUserDetails] = useState(null);
  const [optionList, setOptionList] = useState([]);
  let domainName = getCookie("domainName");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [OpendrowDown, SetOpenDropdown] = useState(false);
  const $element = useRef(null);

  const { success: List } = useSelector(
    (state) => state.getMyClientListReducer
  );

  const { userDetail: userData } = useSelector(
    (state) => state.userDetailReducer
  );

  const { SelectedClient } = useSelector((state) => state.globalReducer);
  const { success } = useSelector((state) => state.retrieveUserDataReducer);
  const screenSizes = useDeviceType();
  const { token } = useToken();

  useEffect(() => {
    if (!token) return;

    dispatch(GetMyClients());
  }, [token]);

  useEffect(() => {
    if (!token) return;

    dispatch(RetrieveUserData());
  }, [token]);

  useEffect(() => {
    if (success) return setUserDetails(success);
    if (userData) return setUserDetails(userData);
    return setUserDetails(JSON.parse(getCookie("userData")));
  }, [userData, success]);

  useEffect(() => {
    let selectedClient = getCookie("selectedClient");

    if (!selectedClient) {
      dispatch(setSelectedClient(false));
    }

    if (!List || !selectedClient) {
      return;
    }

    dispatch(
      setSelectedClient(List?.find((client) => client?.id === selectedClient))
    );
  }, [List]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== "notHide"
      ) {
        SetOpenDropdown(false);
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }

  const restrictedRoutes = ["/welcome-page", "/final-step"];
  const apmRoutes = [
    HOME_PATH,
    WSH_PATH,
    APM_PATH,
    HIERARCHY_PATH,
    `${APM_PATH}${PROFILE_PATH}`,
    `${APM_PATH}${COACHING_PATH}`,
    `${APM_PATH}${COACHING_PATH}/:id`,
  ];

  const checkIsAPM = (location) => {
    return apmRoutes.some((route) => {
      if (!route.includes("/:id")) {
        return route === location;
      } else {
        const dynamicRouteRegex = new RegExp(
          `^${route.replace("/:id", "/[^/]+$")}$`
        );
        return dynamicRouteRegex.test(location);
      }
    });
  };

  const isAPM = checkIsAPM(location);

  useEffect(() => {
    setOptionList(List);
  }, [List]);

  const handleLogout = () => {
    dispatch(Logout(navigate));
    setIsAuthenticated(false);
  };

  const handleUserProfile = () => {
    navigate(`${isAPM ? APM_PATH : JOURNEY_PATH}${PROFILE_PATH}`);
  };

  const handleSearchUser = (e) => {
    setSearchUser(e.target.value);
    const UserNameList = List?.filter((item) =>
      item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
    );
    if (!UserNameList[0]) return setOptionList([{ name: "Data not found.." }]);
    setOptionList(UserNameList);
    if (e.target.value === "") return setOptionList(List);
  };

  const handleSelectUser = ({ id }) => {
    SetOpenDropdown(false);
    setSearchUser("");
    // setOptionList(List);
    dispatch(setSelectedClient(List?.find((client) => client?.id === id)));
    setCookie("selectedClient", id);
  };

  const handleHome = () => {
    removeCookie("selectedClient");
    dispatch(setSelectedClient(false));
    setSearchUser("");
    // setOptionList(List);
  };

  const menuItems = [
    {
      key: "1",
      onClick: handleUserProfile,
      label: <Options isBlack>User Profile</Options>,
    },
    {
      key: "2",
      onClick: handleLogout,
      label: <Options isRed>Logout</Options>,
    },
  ];

  useEffect(() => {
    if (token) return setIsAuthenticated(true);
    return setIsAuthenticated(false);
  }, [location]);

  const isRestricted = () => {
    return restrictedRoutes?.includes(location);
  };

  const handleClickOutside = (event) => {
    if ($element.current && !$element.current.contains(event.target)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openDrawer = () => {
    dispatch(DrawerMenuAction(true));
  };

  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  return (
    <>
      {!isRestricted() && (
        <MainContainer>
          {isAPM && screenSizes !== "desktop" && sidebar && (
            <div ref={$element}>
              {" "}
              <SchedulerSideNav position="absolute" bcolore="white" />{" "}
            </div>
          )}

          <Wrapper>
            {token ? (
              <>
                <Data>
                  {isAPM && screenSizes !== "desktop" ? (
                    <Iconmenu>
                      <MenuIcon onClick={() => setSidebar(!sidebar)} />
                    </Iconmenu>
                  ) : null}
                  {(isMobile || isTablet) && (
                    <DrawerMenu onClick={openDrawer}>
                      <DrawerMenuIcon src={DrawerIcon} height={12} width={18} />
                    </DrawerMenu>
                  )}
                  {isAPM ? (
                    <Link to={APM_PATH}>
                      <Logo src={apmLogoImg} height={30} />
                    </Link>
                  ) : (
                    <Link to={JOURNEY_PATH}>
                      {isMobile ? (
                        <Logo $small src={LogoImgMobile} height={28.8} />
                      ) : (
                        <Logo src={LogoImg} height={35} />
                      )}
                    </Link>
                  )}

                  {domainName &&
                    location !== JOURNEY_PATH &&
                    !isAPM &&
                    !location.includes(ACHIEVEMENTS_PATH) &&
                    !location.includes(PROFILE_PATH) && (
                      <CategoryHeader>
                        <SpanBar></SpanBar>
                        <Goal>{domainName}</Goal>
                      </CategoryHeader>
                    )}

                  {location.includes(ACHIEVEMENTS_PATH) && (
                    <CategoryHeader>
                      <SpanBar></SpanBar>
                      <Goal>Achievements</Goal>
                    </CategoryHeader>
                  )}
                </Data>
                {location === JOURNEY_PATH &&
                userDetails?.type === userTypes?.coach ? (
                  <SearchWrapper>
                    <SearchHeading>Activity For</SearchHeading>
                    <SelectWrapper>
                      <CustomDropDown
                        onClick={() => {
                          SetOpenDropdown(!OpendrowDown);
                        }}
                        id={!OpendrowDown ? "Hide" : "notHide"}
                      >
                        <Dropdowntext>
                          {SelectedClient?.name ?? "Select User"}
                        </Dropdowntext>
                        <DropDownIcon src={Chevron} alt="dropdownIcon" />
                      </CustomDropDown>
                      {OpendrowDown && (
                        <UlWrapper ref={wrapperRef}>
                          <UL id="notHide">
                            <LI id="notHide" isPadding noHover>
                              <SearchUserInput
                                id="notHide"
                                onChange={(e) => handleSearchUser(e)}
                                value={SearchUser}
                              />
                            </LI>
                            {optionList?.map((item, index) => (
                              <LI
                                id="notHide"
                                isSelected={SelectedClient?.name === item?.name}
                                onClick={() => handleSelectUser(item)}
                                key={index}
                              >
                                {item?.name}
                              </LI>
                            ))}
                          </UL>
                        </UlWrapper>
                      )}
                    </SelectWrapper>
                    <HomeIcon
                      onClick={handleHome}
                      src={Home_Icon}
                      alt="Home_icon"
                    />
                  </SearchWrapper>
                ) : null}

                <WelcomeUser>
                  {isAPM ? null : (
                    <CreditBox>
                      <CreditText>Credits</CreditText>
                      <CreditAmount>{getCookie("credits")}</CreditAmount>
                    </CreditBox>
                  )}

                  <ProfileView>
                    {/* <CreditText>{userData?.name}</CreditText> */}
                    <Dropdown
                      overlayClassName="header_dropdown"
                      menu={{ items: menuItems }}
                      trigger={["click"]}
                    >
                      <DropdownToggle title={userData?.name || "Guest"}>
                        <ProfileImg
                          src={userData.display_picture || ProfileAvatar}
                        />
                      </DropdownToggle>
                    </Dropdown>
                  </ProfileView>

                  <AppSwitcher />
                </WelcomeUser>
              </>
            ) : (
              <>
                <Data>
                  <Link to="/">
                    <Logo src={LogoImg} width={120} />
                  </Link>
                </Data>

                <DataLink href="https://arootah.com">
                  <ExitText>Return to</ExitText>
                  <ExitLink>arootah.com</ExitLink>
                </DataLink>
              </>
            )}
          </Wrapper>
          {!isAuthenticated ||
            (location === JOURNEY_PATH &&
              userDetails?.type === userTypes?.coach && (
                <SearchWrapperMob>
                  <SearchHeadingMob>Activity For</SearchHeadingMob>
                  <SelectWrapperMob>
                    <CustomDropDown
                      onClick={() => SetOpenDropdown(!OpendrowDown)}
                      id={!OpendrowDown ? "Hide" : "notHide"}
                    >
                      <Dropdowntext>
                        {SelectedClient?.name ?? "Select User"}
                      </Dropdowntext>
                      <DropDownIcon src={Chevron} alt="dropdownIcon" />
                    </CustomDropDown>
                    {OpendrowDown && (
                      <UlWrapper ref={wrapperRef}>
                        <UL>
                          <LI isPadding noHover>
                            <SearchUserInput
                              onChange={(e) => handleSearchUser(e)}
                              value={SearchUser}
                            />
                          </LI>
                          {optionList?.map((item, index) => (
                            <LI
                              isSelected={SelectedClient?.name === item?.name}
                              onClick={() => handleSelectUser(item)}
                              key={index}
                            >
                              {item?.name}
                            </LI>
                          ))}
                        </UL>
                      </UlWrapper>
                    )}
                  </SelectWrapperMob>

                  <HomeIcon src={Home_Icon} alt="Home_icon" />
                </SearchWrapperMob>
              ))}
        </MainContainer>
      )}
    </>
  );
};

export default Header;

const CreditBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 4px;
  border: 1px solid #072f52;
  border-radius: 4px;
  margin-right: 21px;
`;

const CreditText = styled.span`
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
`;

const CreditAmount = styled.span`
  font-family: Poppins;
  font-size: 18px;
  line-height: 20px;
  color: #fff;

  @media screen and (max-width: 744px) {
    font-size: 14px;
  }

`;

const DropdownToggle = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 1.5px inset #fff;
    border-radius: 50%;
  }
`;

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Options = styled.span`
  color: #fff;
  color: ${({ isRed }) => isRed && "red"};
  color: ${({ isBlack }) => isBlack && "#000"};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
`;

const ProfileView = styled.div`
  display: flex;
  align-items: center;
`;

const WelcomeUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #71aaca;
  background: #05192e;
  height: 60px;
  padding: 17px 0 17px 20px;
  position: sticky;
  z-index: 999;
  top: 0;
`;

const Data = styled.div`
  display: flex;
  align-items: center;
  width: 25%;

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 743px) {
    width: 45%;
  }
`;

const CategoryHeader = styled.div`
  max-width: 100px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 744px) and (max-width: 1024px) {
    max-width: 170px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 250px;
  }
`;

const Iconmenu = styled.div`
  cursor: pointer;
  margin-right: 5px;
`;

const DrawerMenu = styled.div`
  ${({ theme }) => theme.max("sm")`
    width: 50px;
    justify-content: end;
    align-items: center;

    @media screen and (max-width: 500px) {
      width: 40px;
      justify-content: center;
      align-items: center;
    }

  `};

  ${({ theme }) => theme.max("md")`
    width: 50px;
    justify-content: end;
    align-items: center;
  `};

  ${({ theme }) => theme.max("lg")`
    @media screen and (max-width: 1023px) {
      width: 50px;
      justify-content: end;
      align-items: center;
    }

  `};
`;

const Logo = styled.img`
  ${({ theme }) => theme.max("sm")`
    width: 100px;

    @media screen and (max-width: 500px) {
      width: 20px;
    }
    
    ${({ $small }) =>
      $small &&
      css`
      width: 30px;
      margin-left: -10px;
    `}

  `};

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 152px;
  }
`;

const DrawerMenuIcon = styled.img`

  @media screen and (max-width: 743px) {
    width: 20px;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 30px;
  }
`;

const Goal = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #73b1bb;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  ${({ theme }) => theme.max("sm")`
    // display: none;
  font-size: 12px;
  flex-wrap:wrap;
  line-height:12px;
  padding-right:10px;

  `};
`;

const DataLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const ExitText = styled.span`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin-right: 4px;
  @media screen and (max-width: 540px) {
    font-size: 12px;
  }
`;

const ExitLink = styled.span`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  text-decoration: underline;

  @media screen and (max-width: 540px) {
    font-size: 12px;
  }
`;

const SpanBar = styled.div`
  margin: 0 24px;
  background: #73b1bb;
  color: transparent;
  width: 2px;
  height: 26px;
  padding: 0.75px;
  ${({ theme }) => theme.max("sm")`
    // display: none;
  margin: 0 10px;
  `};
`;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 50%;
  ${({ theme }) => theme.max("sm")`
   gap:0.5rem !important;
  
  `};
  @media screen and (max-width: 668px) {
    display: none;
  }
`;
const SearchHeading = styled.h2`
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin: 0;
`;
const HomeIcon = styled.img`
  cursor: pointer;
`;
const SelectWrapper = styled.div`
  position: relative;
  width: 17.5rem;
  ${({ theme }) => theme.max("md")`
  width: 14rem;
   
  `};
  ${({ theme }) => theme.max("sm")`
  width: 12rem;
  
  `};
`;
const SearchWrapperMob = styled.div`
  display: none;
  @media screen and (max-width: 668px) {
    display: flex;
    background-color: #05192e;
    height: 54px;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.4rem;
  }
`;
const MainContainer = styled.div`
  background-color: #05192e;
`;
const SearchHeadingMob = styled.h2`
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin: 0;
`;
const SelectWrapperMob = styled.div`
  width: 14rem;
  position: relative;
`;
const InputSearchUser = styled.div`
  padding-left: 10px;
  width: 100%;
  border: 2px solid #345169;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 3px;
  display: flex;
  align-items: center;
  z-index: 10000;
`;
const UserInput = styled.input`
  width: 100%;
  padding: 6px 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #bcc0c6;
  }
  :focus {
    outline: none;
  }
`;
const Icon = styled.img``;
const UL = styled.div`
  width: 320px;
  min-height: 100px;
  height: auto;
  max-height: 320px;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-bottom: 1rem;
  ${({ theme }) => theme.max("md")`
  width: 265px;
   
  `};
  ${({ theme }) => theme.max("sm")`
   width: 235px;

  
  `};
  @media screen and (max-width: 669px) {
    left: -28px;
    top: 28px;
    width: 270px;
  }
  ${({ theme }) => theme.max("xs")`
    left:-75px;
      top: 25px;

width: 320px;
  `};
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #345169;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #345169;
  }
`;
const UlWrapper = styled.ul`
  padding: 0.5rem 0 0 0;
  z-index: 1000;
  background-color: #05192e;
  position: absolute;
  top: 30px;
  left: -24px;
  list-style: none;
  width: 330px;
  padding-left: 0;
  border: 1px solid #345169;
  border-radius: 5px;

  ${({ theme }) => theme.max("md")`
  width: 275px;
   
  `};
  ${({ theme }) => theme.max("sm")`
   width: 245px;

  
  `};
  @media screen and (max-width: 669px) {
    left: -28px;
    top: 28px;
    width: 280px;
  }
  ${({ theme }) => theme.max("xs")`
    left:-75px;
      top: 25px;

width: 330px;
  `};
`;
const LI = styled.li`
  width: 100%;
  padding: ${({ isPadding }) => (isPadding ? "0.5rem" : "0.5rem")};
  cursor: pointer;

  color: #fff;
  background-color: ${({ isSelected }) => isSelected && "#13273B"};
  :hover {
    background-color: ${({ noHover }) => !noHover && "#13273B"};
  }
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;
const CustomDropDown = styled.div`
  position: absolute;
  top: -25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 3px;

  ${({ theme }) => theme.max("sm")`
  height: 42px;
  top: -21.5px;
  `};
`;
const Dropdowntext = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;
const DropDownIcon = styled.img`
  cursor: pointer;
`;
