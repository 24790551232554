import styled from 'styled-components';

export const Wrapper = styled.div<{ $error: boolean }>`
  width: 100%;
  border: 1px solid #e3e5e9;
  border-radius: 4px !important;
  padding: 2px 0 2px 12px;

  .ant-select.ant-select-single {
    max-width: initial;
    border: none;
    border-radius: initial;
  }

  .ant-select-selector {
    background-color: transparent !important;
    border-bottom: none !important;
    padding: 0 !important;
    height: 32px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0 !important;
  }

  .rc-virtual-list-holder-inner {
    cursor: default;
  }

  .ant-select-item-option-grouped {
    padding-left: 12px;
  }

  .ant-select-item {
    padding: 6px 10px 6px 10px;
  }

  .ant-select-selection-overflow-item {
    display: none;
  }

  .ant-select-dropdown {
    min-width: 280px !important;
    z-index: 1000 !important;
  }

  .ant-select-selection-item {
    button {
      display: none;
    }
  }

  .ant-select-item .ant-select-item-option-content {
    color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
  }

  .ant-select-item.ant-select-item-group {
    padding: 6px 10px 6px 8px;
    color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    pointer-events: none;
    cursor: default !important;

    /* &:first-of-type {
      display: none;
    } */
  }

  ${({ $error }) => $error && `
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-bottom: 1px solid #F00000 !important;
    }
  `}
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  span {
    color: var(--Neutral-500, #737373);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
  }
`;
