import { useState, FC } from "react";
import { Select } from "antd";

import CustomOption from "./CustomOption";
import CreateCustom from './CreateCustom';

import { Wrapper, NotFound } from "./styled";

interface IProps {
  type: "area" | "category";
  text: string;
  options: any;
  error: string;
  value: any;
  isWidth?: boolean;
  disabled?: boolean;
  onSelect: (value: any) => void;
  onCreate: (value: string, callback: (success: any) => void) => void;
  onDelete: (value: string) => void;
}

const modifyOptions = (options: any[], value: any, onDelete: (id: string) => void): any[] => {
  return options.map((option) => {
    if ((option as any).custom) {
      return {
        value: option.value,
        label: (
          <CustomOption
            value={option.value}
            label={option.label}
            disabled={option.value === value?.value}
            onDelete={onDelete}
          />
        ),
        originalLabel: option.label,
      };
    }
    return option;
  });
};

const modifyGroupedAreas = (groupedAreas: any[] | any[], value: any, onDelete: (id: string) => void): any[] | any[] => {
  if (Array.isArray(groupedAreas) && groupedAreas.length > 0) {
    if ('options' in groupedAreas[0]) {
      // Grouped areas case
      return (groupedAreas as any[]).map((group) => ({
        ...group,
        options: modifyOptions(group.options, value, onDelete),
      }));
    } else {
      // Flat list of options case
      return modifyOptions(groupedAreas as any[], value, onDelete);
    }
  }
  return groupedAreas;
};

export const getOptionWithLabel = (value: string, options: any[], customOptions: any[]) => {
  const allOptions = [...options, ...customOptions];
  const option = allOptions.find(i => i.id === value) || allOptions.find(i => i.name === value);

  if (!option && value) {
    return {
      value,
      label: value
    };
  }

  if (!option) {
    return undefined;
  }

  return {
    value: option?.id,
    label: option?.name
  };
}

const CustomSelect: FC<IProps> = ({ type, text, options, error, disabled, onSelect, onCreate, onDelete, value, isWidth, ...rest }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value: string) => {
    setSearchValue(value);
  }

  const handleCreate = (value: string, callback: (success: any) => void) => {
    onCreate(value, callback);
    setSearchValue("");
  }

  const handleSelect = (newValue: any) => {
    onSelect({ ...newValue, label: newValue?.label?.props?.label ?? newValue?.label });
    setSearchValue("");
  }

  return (
    <Wrapper $error={!!error}>
      <Select
        {...rest}
        getPopupContainer={(trigger) => trigger.parentNode.parentNode}
        value={value}
        showSearch
        searchValue={searchValue}
        onSelect={handleSelect}
        style={{
          width: "100%"
        }}
        optionFilterProp="value"
        placeholder="Select"
        autoClearSearchValue={false}
        filterOption={(input, option) =>
          ((typeof option?.label === 'string' ? option.label : option?.originalLabel).toLowerCase() ?? "").includes(input.toLowerCase())
        }
        notFoundContent={type === 'category'
          ? <NotFound>
              <span>You don’t have any categories in this area</span>
            </NotFound>
          : null
        }
        labelInValue
        listHeight={200}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <CreateCustom
                type={type}
                onCreate={handleCreate}
                onSelect={handleSelect}
              />
            </>
          )
        }}
        options={modifyGroupedAreas(options, value, onDelete)}
        disabled={disabled}
        onSearch={handleSearch}
        placement="bottomRight"
      />
    </Wrapper>
  );
};

export default CustomSelect;
