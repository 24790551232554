import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 320px;
  border-radius: 8px;
  border: 1px solid #E3E5E9;
  background: #FFF;
  box-shadow: 6px 1px 13.9px 0px rgba(0, 0, 0, 0.05);
  padding: 16px 17px;
`;

interface IGroup {
  $disabled?: boolean;
}

export const Group = styled.div<IGroup>`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${({ $disabled }) => $disabled && css`
    opacity: .5;
  `}
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const Title = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

export const Label = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`;

export const Button = styled.button`
  width: auto;
  border: none;
  border-radius: 6px;
  background: #4F90A6;
  padding: 0px 10px;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
`;

export const Footer = styled.div``;
