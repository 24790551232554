// @ts-ignore
import { listSubdomains } from "api";
import { ActionTypes } from "./types";

import Notify from "utils/notify";

export const ListSubdomains = (domainId: string, callback: (data: any) => void) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const filtersParams: { [key: string]: any } = {
      domain: domainId
    };

    const { data } = await listSubdomains(filtersParams);

    if (callback) {
      callback(data);
    }

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
