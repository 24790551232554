export const ActionTypes = {
  LOADING: "LIST_SUBDOMAINS_LOADING",
  ERROR: "LIST_SUBDOMAINS_ERROR",
  SUCCESS: "LIST_SUBDOMAINS_SUCCESS",
};

export interface IAction {
  type: string;
  payload: any;
}
