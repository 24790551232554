export const INDEX_PATH = '/';

export const HOME_PATH = '/home';
export const WSH_PATH = '/schedule';
export const APM_PATH = '/time-manager';
export const JOURNEY_PATH = '/journeys';
export const HIERARCHY_PATH = '/hierarchy';
export const COMPLETED_GOALS_PATH = '/goals';
export const ACHIEVEMENTS_PATH = '/achievements';

export const PREMISE_PATH = JOURNEY_PATH + '/premise';
export const MISSION_PATH = JOURNEY_PATH + '/mission';
export const PRIORITIZE_PATH = JOURNEY_PATH + '/prioritize';
export const MEASUREMENT_PATH = JOURNEY_PATH + '/measurement';
export const GOALS_PATH = JOURNEY_PATH + '/goals';
export const PURPOSE_PATH = JOURNEY_PATH + '/purpose';
export const ACTIONS_PATH = JOURNEY_PATH + '/action-plans';
export const MASTER_PATH = JOURNEY_PATH + '/master-plan';

export const SIGN_IN_PATH = '/sign-in';
export const SIGN_UP_PATH = '/sign-up';
export const TERMS_OF_USE_PATH = '/terms-of-use';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const FORGOT_PASSWORD_SUCCESS_PATH = '/forgot-password/success';
export const RESET_PASSWORD_PATH = '/reset-password';

export const WELCOME_PATH = '/welcome';
export const PROFILE_PATH = '/profile';
export const OTP_PATH = '/otp';
export const COACHING_PATH = '/coaching';
