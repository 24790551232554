import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--Neutral-400, #A3A3A3);
  break-inside: avoid;
  break-after: auto;
}`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 7px 14px;
  border-bottom: 1px solid var(--Neutral-400, #A3A3A3);
  background: var(--Neutral-200, #E5E5E5);
}`;

export const Area = styled.span`
  color: var(--Neutral-900, #171717);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}`;

{/* Hidden because no category name in the API response, */}
/* export const Category = styled.span`
  color: var(--Neutral-600, #525252);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  line-height: 24px;
}`; */

export const Body = styled.div`
  padding: 12px;
}`;

export const Content = styled.span`
  color: var(--Neutral-600, #525252);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
}`;
