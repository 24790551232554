import { useState, useRef, useEffect, FC } from 'react';
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';

import { IMissionStatement } from '@types';

// @ts-ignore
import { getCookie } from "utils/StorageVariables";

import {
  IconButton,

  Wrapper,

  Header,
  HeaderItem,
  Logo,
  Span,
  Heading,
  HeaderItemRight,
  HeaderAuthor,

  UnderHeader,
  Order,
  Area,
  Category,

  Body,
  Title,
  SubText,
  Text,

  List,
  ListEmpty,
  ActionItem,
  ActionItemHeader,
  ActionItemContent,
  ActionItemHr,

  Footer,
  Copy,

  Row,
  Col
} from './styled';

// @ts-ignore
import logo from "assets/images/header_logo_modern.svg";

// @ts-ignore
import printIcon from "assets/images/print-dark.svg";

// @ts-ignore
import { GetMissionStatement } from "Redux/GetMissionStatement/action";

const pageStyles = () => {
  return `
    @page { size: portrait; }
    @page { margin-top: 32px; margin-bottom: 0; }
    @page :first { margin-top: 0; margin-bottom: 50px; }
  `;
};

interface IProps {
  data?: any;
  journeyData?: string;
}

interface IStateSlice {
  getMissionStatementReducer: {
    missionStatement: IMissionStatement;
    loading: boolean;
  };
}

const Print: FC<IProps> = ({ data }) => {
  const dispatch = useDispatch();

  const [isPrint, setIsPrint] = useState(false);
  const $componentRef = useRef(null);

  const { missionStatement, loading: misisonStatementLoading } = useSelector(
    (state: IStateSlice) => state.getMissionStatementReducer
  );

  const domainName = getCookie("domainName");
  const userData = JSON.parse(getCookie("userData"));

  const handlePrint = useReactToPrint({
    documentTitle: "Master Mission Statement",
    onAfterPrint: () => setIsPrint(false),
    content: () => $componentRef.current,
  });

  useEffect(() => {
    if (data?.goal?.id) {
      dispatch(GetMissionStatement(data?.goal?.id));
    }
  }, [data?.goal?.id]);

  useEffect(() => {
    if (isPrint) {
      handlePrint();
    }
  }, [isPrint]);

  const handleClick = () => {
    setIsPrint(true);
  };

  const actionItems = data?.goal?.actions
    .filter((action: any) => !action.completed)
    .filter((action: any) => action.context || action.startline || action.deadline || action.accountability || action.type)
  || [];
  const loading = isPrint || misisonStatementLoading;

  return (
    <>
      <IconButton disabled={loading} onClick={handleClick}>
        {loading ? <Spin size="small" /> : <img src={printIcon} alt="Print" />}
      </IconButton>

      {(isPrint || true) ? <Wrapper ref={$componentRef}>
        <style>{pageStyles()}</style>

        <Header>
          <HeaderItem>
            <Logo src={logo} alt="Goal Manager" />
            <Span />
            <Heading>{domainName} Goal Setting by Arootah</Heading>
          </HeaderItem>
          <HeaderItemRight>
            <HeaderAuthor>{userData?.name}</HeaderAuthor>
          </HeaderItemRight>
        </Header>

        <UnderHeader>
          <Order>{data?.goal?.priority}</Order>
          <Area>{data?.name}</Area>
          <Category>{data?.goal?.category_name}</Category>
        </UnderHeader>

        <Body>
          <Title>Goal</Title>
          <SubText>{data?.goal?.goal_name || '—'}</SubText>

          <Row $border>
            <Col>
              <Text $title>Startline</Text>
              <Text $p>
                {data?.goal?.startline
                  ? moment(data?.goal?.startline).format("MM/DD/YYYY")
                  : "—"}
              </Text>
            </Col>

            <Col>
              <Text $title>Deadline</Text>
              <Text $p>
                {data?.goal?.deadline
                  ? moment(data?.goal?.deadline).format("MM/DD/YYYY")
                  : "—"}
              </Text>
            </Col>

            <Col>
              <Text $title>Accountability</Text>
              <Text $p>{data?.goal?.accountability || '—'}</Text>
            </Col>

            <Col>
              <Text $title>Measurement</Text>
              <Text $p>{data?.goal?.measurement_current_value ||
                data?.goal?.measurement_target_value
                ? `${data?.goal?.measurement_current_value ??
                " — "
                }/${data?.goal?.measurement_target_value ??
                " — "
                } ${data?.goal?.measurement_metric_name ?? '—'}`
                : data?.goal?.measurement}</Text>
            </Col>
          </Row>

          <Title>Purpose</Title>

          <Row $border>
            <Col $hr>
              <Text $title>Rewards</Text>
              <Text $p>{data?.goal?.pleasure || '—'}</Text>
            </Col>

            <Col>
              <Text $title>Consequences</Text>
              <Text $p>{data?.goal?.pain || '—'}</Text>
            </Col>
          </Row>

          <Title>Mission Statement</Title>

          {data?.goal?.has_mission_statement && missionStatement ? (
            <Row $border>
              <Col>
                <Text $p>{missionStatement?.conclusion}</Text>
              </Col>
            </Row>
          ) : (
            <ListEmpty $sm>
              <Text $p>No Mission statement.</Text>
            </ListEmpty>
          )}

          <Title>Actions</Title>

          <List>
            {actionItems.length === 0 ? (
              <ListEmpty>
                <Text $p>No actions are associated.</Text>
              </ListEmpty>
            ) : null}

            {actionItems.map((action: any, index: number) => (
              <ActionItem key={action.id}>
                <Row>
                  <Col>
                    <Text $title>Action {index + 1}</Text>
                  </Col>
                </Row>

                <ActionItemHeader>
                  <Text $p>{action.context}</Text>
                </ActionItemHeader>

                <ActionItemContent>
                  <ActionItemHr />
                  <Row $white>
                    <Col>
                      <Text $title>Startline</Text>
                      <Text $p>{action?.startline
                        ? moment(action?.startline).format("MM/DD/YYYY")
                        : "—"}</Text>
                    </Col>

                    <Col>
                      <Text $title>Deadline</Text>
                      <Text $p>{action?.deadline
                        ? moment(action?.deadline).format("MM/DD/YYYY")
                        : "—"}</Text>
                    </Col>

                    <Col>
                      <Text $title>Accountability</Text>
                      <Text $p>{action?.accountability || '—'}</Text>
                    </Col>

                    <Col>
                      <Text $title>Type</Text>
                      <Text $p>{action?.type || '—'}</Text>
                    </Col>
                  </Row>
                </ActionItemContent>
              </ActionItem>
            ))}
          </List>
        </Body>

        <Footer>
          <Copy>Copyright &copy; Arootah {moment().year()}. All Rights Reserved</Copy>
        </Footer>
      </Wrapper> : null}
    </>
  );
}

export default Print;
