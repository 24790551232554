// @ts-ignore
import { getOverallProgress } from "api";
import Notify from "utils/notify";

import { ActionTypes } from "./types";

interface IPayload {
  domain?: string;
  area?: string;
}

export const GetOverallProgress = (payload = {} as IPayload) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const filtersParams: { [key: string]: any } = {};

    if (payload?.domain) {
      filtersParams['masterplan_id'] = payload?.domain;
      filtersParams['user_area_name'] = payload?.area;
    }

    const { data } = await getOverallProgress(filtersParams);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
