import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  ${({ theme }) => theme.max("sm")`
    flex: 1;
  `}
`;

interface IRow {
  $primary?: boolean;
}

export const Row = styled.div<IRow>`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  ${({ theme }) => theme.min("sm")`
    border-bottom: 1px solid rgba(229, 231, 235, 0.50);
  `}

  ${({ theme }) => theme.max("sm")`
    :not(:first-of-type):not(:last-of-type) {
      border-bottom: 1px solid rgba(229, 231, 235, 0.50);
    }
  `}

  ${({ $primary }) => $primary && css`
    height: 42px;

    ${({ theme }) => theme.max("sm")`
      height: 20px;
    `}
  `}

  ${({ $primary }) => !$primary && css`
    height: 46px;

    ${({ theme }) => theme.max("sm")`
      min-height: 28px;
      height: initial;
      padding: 4px 0;
    `}
  `}
`;

interface IValueRow {
  $small?: boolean;
}

export const ValueRow = styled.div<IValueRow>`
  display: flex;
  align-items: center;
  gap: 64px;

  ${({ $small }) => $small && css`
    gap: 44px;
  `}
`;

interface ICol {
  $primary?: boolean;
  $secondary?: boolean;
}

export const Col = styled.div<ICol>`
  display: flex;

  ${({ $primary }) => $primary && css`
    flex: 1;
  `}

  ${({ $primary }) => $primary && css`
    align-items: center;
  `}
`;

export const Label = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;

  ${({ theme }) => theme.max("sm")`
    font-size: 10px;
    line-height: 14px;
  `}
`;

export const Value = styled.span`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 32px;

  ${({ theme }) => theme.max("sm")`
    font-size: 10px;
    line-height: 14px;
  `}
`;

interface ITitle {
  $bold?: boolean;
}

export const Title = styled.span<ITitle>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */

  ${({ $bold }) => $bold && css`
    justify-content: center;
    color: #000;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: 0;

    ${({ theme }) => theme.max("sm")`
      width: 32px;
    `}
  `}

  ${({ theme }) => theme.max("sm")`
    font-size: 10px;
    line-height: 14px;
  `}
`;

interface IIcon {
  $rotate?: number;
}

export const Icon = styled.img<IIcon>`
  width: 18px;
  height: 18px;
  
  ${({ $rotate }) => $rotate && css`
    transform: rotate(${$rotate}deg);
  `}
`;
