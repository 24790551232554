import { useState, createRef, FC, ChangeEvent, KeyboardEvent, FormEvent } from 'react';
import { InputField, Button } from 'elements';

import {
  Wrapper,
  Label,
  Row
} from './styled';

interface IProps {
  type: "area" | "category";
  onCreate: (value: string, callback: (success: any) => void) => void;
  onSelect: (value: any) => void;
}

function capitalize(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const CreateCustom: FC<IProps> = ({ type, onCreate, onSelect }) => {
  const [value, setValue] = useState('');
  const $input = createRef<HTMLInputElement>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue(value);
    const inputLength = value.trim().length;
  
    if (inputLength > 0 && inputLength < 3) {
      e.target.setCustomValidity('Min. 3 characters required');
    } else if (inputLength > 32) {
      e.target.setCustomValidity('Max. 32 characters allowed');
    } else {
      e.target.setCustomValidity('');
    }
  };  

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onCreate(value.trim(), (success) => {
      if (success) {
        $input.current?.setCustomValidity('');

        setValue('');
        onSelect({
          value: success.id,
          label: success.name
        });
      }
      else if ($input.current) {
        $input.current.setCustomValidity(`${capitalize(type)} already exists`);
        $input.current.reportValidity();
      }
    });
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Label>Add Custom {capitalize(type)}</Label>
      <Row>
        <InputField
          // @ts-ignore
          value={value}
          containerStyle={{ flex: 1 }}
          placeholder={`Enter ${capitalize(type)}`}
          required
          max={32}
          error={!!(value && value.length < 2)}
          ref={$input}
          onChange={handleChange}
          onKeyDown={(e: KeyboardEvent) => e.stopPropagation()}
        />
        <Button styleType="secondary" disabled={!value.trim()}>Save</Button>
      </Row>
    </Wrapper>
  );
}

export default CreateCustom;
