import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 52px;

  ${({ theme }) => theme.max("sm")`
    margin-top: 0;
  `}
`;

interface IRow {
  $primary?: boolean;
}

export const Row = styled.div<IRow>`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  border-bottom: 1px solid rgba(229, 231, 235, 0.50);

  ${({ $primary }) => $primary && css`
    height: 42px;

    ${({ theme }) => theme.max("sm")`
      height: 20px;
      border-bottom: 0;
    `}
  `}

  ${({ $primary }) => !$primary && css`
    ${({ theme }) => theme.max("sm")`
      min-height: 28px;
      height: initial;
      padding: 4px 0px;

      :last-of-type {
        border-bottom: 0;
      }
    `}
  `}
`;

interface ICol {
  $primary?: boolean;
  $secondary?: boolean;
}

export const Col = styled.div<ICol>`
  display: flex;

  ${({ $primary }) => $primary && css`
    flex: 1;
  `}

  ${({ $secondary }) => $secondary && css`
    align-items: center;
    justify-content: center;
    width: 45px;
  `}
`;

export const Label = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: -0.14px;

  ${({ theme }) => theme.max("sm")`
    font-size: 10px;
    line-height: normal;
    letter-spacing: -0.1px;
  `}
`;

interface ITitle {
  $bold?: boolean;
}

export const Title = styled.span<ITitle>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;

  ${({ theme }) => theme.max("sm")`
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.1px;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  ${({ theme }) => theme.max("sm")`
    width: 16px;
    height: 16px;
  `}
`;

export const Icon = styled.img`
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}
`;
