import styled, { css } from 'styled-components';

export const DropdownToggle = styled.div`
  cursor: pointer;
  user-select: none;
  width: 266px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  background: var(--Fill-Text-field-White, #FFF);
  
  span {
    color: var(--Stroke-Text-Body-Prussian-blue, #1B2A3D);
    font-variant-numeric: lining-nums tabular-nums;/
    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
