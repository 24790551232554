import { FC } from 'react';
import ReactApexChart from "react-apexcharts";
import { RadioButton } from "elements";

import {
  Wrapper,

  Categories,
  Category,
  CheckboxLabel
} from './styled';

import { options } from './chart';

import useWindowSize from "utils/useWindowSize";
import { useQueryParams } from 'utils/useQueryParams';

// @ts-ignore
import { BREAKPOINTS } from "theme/mixins";

interface IData {
  id: string;
  label: string;
  goal: number;
  plan: number;
}

interface IProps {
  data: IData[];
}

const ColumnChart: FC<IProps> = ({ data }) => {
  const { width } = useWindowSize();

  const { journey, trend, setQueryParams } = useQueryParams();

  const selectedItem = trend ? parseInt(trend) - 1 : 0;

  const extendedData = [
    ...data,
    ...Array.from({ length: Math.max(0, 7 - data.length) }, () => ({ id: 'null', plan: 0, goal: 0, label: null }))
  ];

  const chartOptions = {
    ...options,
    xaxis: {
      ...options.xaxis,
      categories: extendedData.filter(item => item.label).map(item => item.label)
    },
    fill: {
      colors: journey ? ['#7400CF', '#17C1D9'] : ['#E88614', '#17C1D9']
    }
  };

  const chartData = [
    {
      name: 'Plan Progress',
      data: extendedData.map(item => item.plan)
    }, {
      name: 'Goal Completion Rate',
      data: extendedData.map(item => item.goal)
    }
  ];

  const handleSelect = (index: number) => () => {
    setQueryParams({ trend: (index + 1).toString() });
  }

  if (width && width < BREAKPOINTS.sm)
    return null;

  return (
    <Wrapper $active={selectedItem}>
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="bar"
        width="100%"
        height={240}
      />

      <Categories>
        {extendedData.map((item, index: number) => (
          <Category key={index}>
            {item.label ? <RadioButton
              id={item.id}
              value={item.id}
              label={<CheckboxLabel>{item.label}</CheckboxLabel>}
              checked={selectedItem === index}
              name="items"
              onChange={handleSelect(index)}
            /> : null}
          </Category>
        ))}
      </Categories>
    </Wrapper>
  );
}

export default ColumnChart;
